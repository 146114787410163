import React, { useState, useEffect, useRef } from 'react';
import "../../styles/searchSelect.css";

const statusOptions = [
  { value: 'autorazTrue', label: 'АвтоРазнесено' },
  { value: 'razTrue', label: 'Разнесено' },
  { value: 'razFalse', label: 'Не разнесено' },
];

const MultiSelectFieldStatus = ({ value = [], onChange, placeholder = "Добавить статус" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(statusOptions);
  const [selectedOptions, setSelectedOptions] = useState(Array.isArray(value) ? value : []);
  const wrapperRef = useRef(null);

  const handleSelect = (option) => {
    const optionExists = selectedOptions.includes(option.value);
    let updatedSelectedOptions;

    if (optionExists) {
      updatedSelectedOptions = selectedOptions.filter((selected) => selected !== option.value);
    } else {
      updatedSelectedOptions = [...selectedOptions, option.value];
    }

    setSelectedOptions(updatedSelectedOptions);
    onChange(updatedSelectedOptions); 
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const filtered = statusOptions.filter((option) =>
      option.label.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
    if (!isOpen) {
      setSearchTerm('');
      setFilteredOptions(statusOptions); 
    }
  };

  return (
    <div className="searchable-select" ref={wrapperRef}>
      <div 
        className="form-select form-select-sm d-flex justify-content-between align-items-center" 
        onClick={toggleDropdown}
        style={{ cursor: 'pointer' }}
      >
        <span>
          {selectedOptions.length > 0
            ? selectedOptions
                .map((val) => {
                  const option = statusOptions.find(opt => opt.value === val);
                  return option ? option.label : '';
                })
                .join(', ')
            : placeholder}
        </span>
      </div>

      {isOpen && (
        <div className="select-dropdown">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Поиск..."
            value={searchTerm}
            onChange={handleSearchChange}
            onClick={(e) => e.stopPropagation()}
          />
          <ul className="list-group">
            {filteredOptions.length === 0 ? (
              <li className="list-group-item">Нет результатов</li>
            ) : (
              filteredOptions.map((option) => (
                <li
                  key={option.value}
                  className="list-group-item"
                  onClick={() => handleSelect(option)}
                >
                  {option.label}
                  {selectedOptions.includes(option.value) && (
                    <span className="red-x">✖</span>
                  )}
                </li>
              ))
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MultiSelectFieldStatus;
