import SideBar from '../SideBar';
import { useState, useEffect, useRef, useCallback  } from 'react';
import api from '../../api';
import ReactLoading from 'react-loading';
import Header from "../Header";
import { useLocation, useNavigate} from 'react-router-dom';
import ProtectedContent from '../ProtectedContent';

const OrganizationList = () => {

    const [organizations, setOrganizations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 25;
    const [checkedOrganizations, setCheckedOrganizations] = useState([]);

    const [nameFilter, setNameFilter] = useState('');
    const [iinbinFilter, setinnbinFilter] = useState('');
    const [archivedFilterYES, setArchivedFilterYES] = useState(false);
    const [archivedFilterNO, setArchivedFilterNO] = useState(false);
    const [startDateFilterOne, setStartOneDateFilter] = useState('');
    const [startDateFilterTwo, setStartTwoDateFilter] = useState('');

    const location = useLocation();
    const navigate = useNavigate();
    const searchIDofelem = location.search.replace("?","");

    const fetchOrganizations = async (offset) => {
        setLoading(true);
        try {
            console.log(location)
            if (searchIDofelem){
                const response = await api.post('/api/v1/organization/organizationfilter/', {
                    id:searchIDofelem,
                    is_active : [true,false]
                });
                setOrganizations(response.data);
            }else{
                const response = await api.get(`api/v1/organization/?limit=${limit}&offset=${offset}`);
                setOrganizations(prevOrganizations => {
                    const newOrg = response.data.results.filter(newOrg => 
                        !prevOrganizations.some(prevOrganizations => prevOrganizations.id === newOrg.id)
                    );
                    return [...prevOrganizations, ...newOrg];
                });
                if (!response.data.next) {
                    setHasMore(false);
                }
            }
            
        } catch (error) {
            console.error('Error fetching organizations', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setOrganizations([]); // Clear existing payments
        setOffset(0); // Reset offset
        setHasMore(true);
        fetchOrganizations();
    }, [location]);

    useEffect(() => {
        if (hasMore) {
            fetchOrganizations(offset);
        }
    }, [offset, hasMore]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        if (searchIDofelem){
            {organizations.map((organization) => (
                setNameFilter(organization.name)
            ))}
        }
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);


    const handleUpdateOrganizations = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(checkedOrganizations.map(id => 
                api.patch(`api/v1/organization/${id}/update`, { is_active: isActive })
            ));
            // Refresh the list after updating
            setOrganizations([]);
            setOffset(0);
            setHasMore(true);
            setCheckedOrganizations([])
        } catch (error) {
            console.error(`Error ${isActive ? 'unarchiving' : 'archiving'} organizations`, error);
        } finally {
            setLoading(false);
        }
    };

    const handleArchiveOrganizations = () => handleUpdateOrganizations(false);
    const handleUnarchiveOrganizations = () => handleUpdateOrganizations(true);
    

    const handleCheckboxChange = (orgId) => {
        setCheckedOrganizations(prevOrgs => {
            if (prevOrgs.includes(orgId)) {
                return prevOrgs.filter(id => id !== orgId);
            } else {
                return [...prevOrgs, orgId];
            }
        });
    };


    const handleSearch = async () => {
        try {
            // console.log(nameFilter,archivedFilterYES,archivedFilterNO,startDateFilterOne,startDateFilterTwo)
            const response = await api.post('/api/v1/organization/organizationfilter/', {
                name: nameFilter.toLowerCase(), 
                is_active : [!archivedFilterYES,archivedFilterNO], 
                startDateOne:startDateFilterOne,
                iinbin: iinbinFilter,
                startDateTwo:startDateFilterTwo,
               
            });
            console.log('organisations fetched:', response.data); 
            setOrganizations(response.data); // Изначально показываем всех сотрудников
        } catch (error) {
            console.error('Error fetching project:', error);
        }
    };

    const handleReset = () => {
        const search = location.search
        navigate(location.search.replace(search,'')); 
        window.location.reload();
    };
    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allOrgs = organizations.map(organization => organization.id);
            setCheckedOrganizations(allOrgs);
        } else {
            setCheckedOrganizations([]);
        }
    };

    // if (loading) {
    //     return (
    //         <div className="position-absolute top-50 start-50 translate-middle">
    //             <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
    //         </div>);
    // }
    const allChecked = organizations.length > 0 && organizations.every(org => checkedOrganizations.includes(org.id));

    return (
        <>
        <div className="container-fluid" translate="no">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="payment-management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Организации</h3></div>
                                    <div className="mb-3 p-3 border rounded" style={{display:'block'}} >
                                    <h5>Фильтры:</h5>
                                    <div className="row">
                                        <div className="col">
                                            <label className='form-label'>Наименование организации</label>
                                            <input
                                                type="text"
                                                placeholder="Введите наименование проекта"
                                                value={nameFilter}
                                                onChange={e => setNameFilter(e.target.value)}
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div  className="col">
                                            <label className='form-label'>БИН/ИИН</label>
                                            <input
                                                type="text"
                                                placeholder="БИН/ИИН"
                                                value={iinbinFilter}
                                                onChange={e => setinnbinFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className='form-label'>Дата создания</label>
                                            <div className="d-flex">
                                            <span  style={{marginTop:'7px',marginRight:'7px'}}>с</span>
                                                <input
                                                type="date"
                                                value={startDateFilterOne}
                                                onChange={e => setStartOneDateFilter(e.target.value)}
                                                className="form-control form-control-sm"
                                            />
                                                <span className="mx-2" style={{marginTop:'7px'}}>—</span><span  style={{margin:'7px',marginRight:'7px'}}>до</span>
                                                <input
                                                type="date"
                                                value={startDateFilterTwo}
                                                onChange={e => setStartTwoDateFilter(e.target.value)}
                                                className="form-control form-control-sm"
                                            />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <label >Архивный</label>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckYES"
                                                    checked={archivedFilterYES}
                                                    onChange={e => setArchivedFilterYES(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckYES">
                                                    Да
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckNO"
                                                    checked={archivedFilterNO}
                                                    onChange={e => setArchivedFilterNO(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckNO">
                                                    Нет
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col align-self-end">
                                            <button
                                                onClick={handleSearch}
                                                className="btn btn-primary mt-3"
                                            >
                                                Поиск
                                            </button>
                                            <button
                                                onClick={handleReset}
                                                className="btn btn-outline-secondary mt-3"
                                            >
                                                Сбросить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div>
                                        <ProtectedContent perms={'organization.change_organization'}>
                                            <button 
                                                onClick={handleArchiveOrganizations} 
                                                className="btn btn-secondary me-2"
                                                disabled={checkedOrganizations.length === 0}
                                            >
                                                Архивировать выбранные
                                            </button>
                                            <button 
                                                onClick={handleUnarchiveOrganizations} 
                                                className="btn btn-success me-2"
                                                disabled={checkedOrganizations.length === 0}
                                            >
                                                Разархивировать выбранные
                                            </button>
                                        </ProtectedContent>
                                        <button onClick={() => setOffset(0)} className="btn btn-primary">Обновить</button>
                                    </div>
                                </div>
                                <div ref={containerRef} className="table-responsive" style={{ height: '75vh', overflowY: 'scroll' }}>
                                    <table className="table">
                                        <thead className="table-light">
                                            <tr>
                                            <th>
                                                    <input 
                                                        type="checkbox" 
                                                        checked={allChecked}
                                                        onChange={handleSelectAllChange}
                                                    />
                                                </th>
                                                <th>Наименование организации</th>
                                                <th>БИН/ИИН</th>
                                                <th>Дата создания</th>
                                                <th>Архивный</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {organizations.map((organization) => (
                                                <tr key={organization.id}>
                                                    <td>
                                                        <input 
                                                            type="checkbox"
                                                            checked={checkedOrganizations.includes(organization.id)}
                                                            onChange={() => handleCheckboxChange(organization.id)}
                                                        />
                                                    </td>                        <td>{organization.name ? organization.name : ''}</td>
                                                    <td>{organization.bin ? organization.bin : ''}</td>
                                                    <td>{organization.created ? organization.created : ''}</td>
                                                    <td>{organization.is_active ? 'Нет' : 'Да'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {loading && 
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default OrganizationList;