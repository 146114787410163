// Number Formatting Function
function formatNumber(num) {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(0) + ' млн.';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(0) + ' тыс.';
    } else {
        return num.toString();
    }
}

function SemiCircleChart({min, max, value}){

    const angle = (value / max) * 180;
    const style = {'--angle': angle + 'deg'};

    const percent = (value / max) * 100;

    return (
        <div className="Analytics__Chart__Gauge">
            <div className="Analytics__Chart__Background">
                <div className="Analytics__Chart__Percentage" style={style}></div>
                <div className="Analytics__Chart__Mask"></div>
                <span className="Analytics__Chart__Value">{ formatNumber(value) }</span>
                <span className="Analytics__Chart__PercentValue">{ percent.toFixed(2) }%</span>
            </div>
            <span className="Analytics__Chart__Min">{ formatNumber(min) }</span>
            <span className="Analytics__Chart__Max">{ formatNumber(max) }</span>
        </div>)
}

export default SemiCircleChart;