import { createContext, useContext, useState, useEffect } from "react";
import api from './api';
const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [user_groups, setUserGroups] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const storedUser = sessionStorage.getItem('user');
                const storedPermissions = sessionStorage.getItem('permissions');
                const storedUserGroups = sessionStorage.getItem('user_groups');
                const accessToken = localStorage.getItem('access');

                if (accessToken && storedUser && storedPermissions && storedUserGroups) {
                    setUser(JSON.parse(storedUser));
                    setPermissions(JSON.parse(storedPermissions));
                    setUserGroups(JSON.parse(storedUserGroups));
                } else if (accessToken) {
                    // Only make the API call if we have an access token
                    try {
                        const response = await api.get('/api/v1/user-permissions/');
                        if (response.status === 200) {
                            setUser(response.data);
                            setPermissions(response.data.permissions || []);
                            setUserGroups(response.data.groups || []);
                            
                            sessionStorage.setItem('user', JSON.stringify(response.data));
                            sessionStorage.setItem('permissions', JSON.stringify(response.data.permissions || []));
                            sessionStorage.setItem('user_groups', JSON.stringify(response.data.groups || []));
                        }
                    } catch (error) {
                        console.error('Error fetching user data:', error);
                        // If the API call fails, clear the stored tokens
                        logout();
                    }
                }
            } catch (error) {
                console.error('Error in auth flow:', error);
                logout();
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        if (user) {
            const accessToken = localStorage.getItem('access');
            const websocket = new WebSocket(`ws://localhost:8001/ws/permissions/?token=${accessToken}`);
    
            websocket.onopen = () => console.log("WebSocket connected");
    
            websocket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                setPermissions(data.permissions);
                sessionStorage.setItem('permissions', JSON.stringify(data.permissions));
            };
    
            websocket.onerror = (error) => console.error("WebSocket error:", error);
    
            websocket.onclose = () => console.log("WebSocket closed");
    
            // Clean up the WebSocket on unmount or when user changes
            return () => websocket.close();
        }
    }, [user]);


    const login = (userData) => {
        setUser(userData);
        setPermissions(userData.permissions || []);
        setUserGroups(userData.groups || []);

        sessionStorage.setItem('user', JSON.stringify(userData));
        sessionStorage.setItem('permissions', JSON.stringify(userData.permissions || []));
        sessionStorage.setItem('user_groups', JSON.stringify(userData.groups || []));
        localStorage.setItem('access', userData.access);
        localStorage.setItem('refresh', userData.refresh);
    };

    const logout = () => {
        setUser(null);
        setPermissions([]);
        setUserGroups([]);
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('permissions');
        sessionStorage.removeItem('user_groups');
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
    };    
    
    if (loading) {
        return <div>Loading...</div>;
    };


    return (
        <AuthContext.Provider value={{ user, permissions, user_groups, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}