import { Modal, Form, Button, Dropdown } from "react-bootstrap";
import { useState, useEffect } from "react";
import api from "../../api";
import APISearchableSelect from "./SearchableSelect";
import useFetchData from "../../functions/UprData";
import ReactLoading from 'react-loading';

const CreateRuleModal = ({ showModal, setShowModal, rules, setRules }) => {
    const [newRuleGroup, setNewRuleGroup] = useState({
        name: '',
        type_of_comparison: '',
        rules: [
            {
                id: 1,
                parameters: [
                    { parameter: '', comparison: '', value: '' }
                ]
            }
        ],
        project: '',
        caption: '',
        start_date: '',
        end_date: '',
        company: ''
    });

    const [comparisons, setComparisons] = useState(null);
    const [errors, setErrors] = useState({});
    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);



    const parameters = [
      { key: 'company_account', label: 'Банковский счет организации' },
      { key: 'total_sum', label: 'Сумма платежа' },
      { key: 'document_type', label: 'Тип документа' },
      { key: 'type_of_operation', label: 'Тип операции' },
      { key: 'counterparty', label: 'Контрагент' },
      { key: 'counterparty_account', label: 'Банковский счет контрагента' },
      { key: 'purpose_of_payment', label: 'Назначение' },
      { key: 'comment', label: 'Комментарий' }
  ];

    const fetchComparisons = useFetchData('/api/v1/bills/type_of_comparisons/', setComparisons);

    const handleSwitchChange = () => {
        setIsSwitchOn(!isSwitchOn);
        if (!isSwitchOn) {
            setNewRuleGroup({
                ...newRuleGroup,
                start_date: '',
                end_date: ''
            }
            )
        }
    };


  useEffect(() => {
    if (!comparisons) {
      const fetchData = async () => {
        setLoading(true);
        try {
          await fetchComparisons();
        } catch (error) {
          console.error('Error fetching data', error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [comparisons]);
  
  if (loading) {
    return (
        <div className="position-absolute top-50 start-50 translate-middle">
            <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
        </div>
    );
}

    const handleModalClose = () => {
        setShowModal(false);
        setNewRuleGroup({
            name: '',
            type_of_comparison: '',
            rules: [
              {
                id: 1,
                parameters: [
                  { parameter: '', comparison: '', value: '' }
                ]
              }
            ],
            project: '',
            caption: '',
            start_date: '',
            end_date: '',
            company:''
          })
        setErrors({});
    };

    const handleChange = (e) => {
      const { name, value } = e.target;
      setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[name]; 
          return updatedErrors;
      });
      setNewRuleGroup({
        ...newRuleGroup,
        [name]: value
      });
    };

    const formatDataForBackend = () => {
      const formattedRules = newRuleGroup.rules.map(rule => {
          const formattedRule = {};
          rule.parameters.forEach(param => {
              formattedRule[param.parameter] = param.value;
              formattedRule[`${param.parameter}_comparison`] = param.comparison.id;
          });
          return formattedRule;
      });

      return {
          name: newRuleGroup.name,
          type_of_comparison: newRuleGroup.type_of_comparison,
          project: newRuleGroup.project,
          caption: newRuleGroup.caption,
          start_date: newRuleGroup.start_date ? newRuleGroup.start_date : null,
          end_date: newRuleGroup.end_date ? newRuleGroup.end_date : null,
          rules: formattedRules,
          company: newRuleGroup.company
      };
    };

    const validateForm = () => {
      let errors = {};
      if (!newRuleGroup.name.trim()) errors.name = "Название правила обязательно";
      if (!newRuleGroup.type_of_comparison) errors.type_of_comparison = "Выберите тип сравнения";
      if (!newRuleGroup.project) errors.project = "Выберите проект";
      if (!newRuleGroup.caption) errors.caption = "Выберите статью затрат";
      if (!newRuleGroup.company) errors.company = "Выберите организацию";
      
      newRuleGroup.rules.forEach((rule, ruleIndex) => {
          rule.parameters.forEach((param, paramIndex) => {
              if (!param.parameter) errors[`rule_${ruleIndex}_param_${paramIndex}`] = "Выберите параметр";
              if (!param.comparison) errors[`rule_${ruleIndex}_comparison_${paramIndex}`] = "Выберите сравнение";
              if (!param.value.trim()) errors[`rule_${ruleIndex}_value_${paramIndex}`] = "Введите значение";
          });
      });

      setErrors(errors);
      return Object.keys(errors).length === 0;
    };

    const handleSubmit = async () => {
      if (validateForm()) {
          setIsSaving(true); 
          try {
              const formattedData = formatDataForBackend();
              const response = await api.post('api/v1/bills/auto-spacing-rule/create/', formattedData);
              if (response.status === 201) {
                  console.log('successfully created new rule');
                  setRules([...rules, response.data]);
                  handleModalClose();
              }
          } catch (error) {
              console.log(error);
          } finally {
              setIsSaving(false); 
          }
      }
  };

    const addRule = () => {
        setNewRuleGroup(prev => ({
          ...prev,
          rules: [
            ...prev.rules,
            {
              id: prev.rules.length + 1,
              parameters: [{ parameter: '', comparison: '', value: '' }]
            }
          ]
        }));
    };

    const removeRule = (index) => {
        setNewRuleGroup(prev => ({
          ...prev,
          rules: prev.rules.filter((_, i) => i !== index)
        }));
    };

    const addParameter = (ruleIndex) => {
      setNewRuleGroup(prev => ({
        ...prev,
        rules: prev.rules.map((rule, i) => 
          i === ruleIndex
            ? {
                ...rule,
                parameters: [...rule.parameters, { parameter: '', comparison: '', value: '' }]
              }
            : rule
        )
      }));
    };

    const removeParameter = (ruleIndex, paramIndex) => {
      if (newRuleGroup.rules[ruleIndex].parameters.length > 1) {
        setNewRuleGroup(prev => ({
          ...prev,
          rules: prev.rules.map((rule, i) => 
            i === ruleIndex
              ? {
                  ...rule,
                  parameters: rule.parameters.filter((_, j) => j !== paramIndex)
                }
              : rule
          )
        }));
      }
    };

    const handleParameterChange = (ruleIndex, paramIndex, field, value) => {
      setNewRuleGroup(prev => ({
        ...prev,
        rules: prev.rules.map((rule, i) => 
          i === ruleIndex
            ? {
                ...rule,
                parameters: rule.parameters.map((param, j) =>
                  j === paramIndex ? { ...param, [field]: value } : param
                )
              }
            : rule
        )
      }));
      setErrors(prev => ({ ...prev, [`rule_${ruleIndex}_${field}_${paramIndex}`]: undefined }));

    };

    const getDefaultComparison = (param=null) => {
      if (param.parameter === 'total_sum') {
          return comparisons?.filter(comparison => comparison.is_integer === true)[0];
        } else if (param.parameter === 'purpose_of_payment' || param.parameter === 'comment') {
          return comparisons?.filter(comparison => comparison.is_string === true)[0];
        } else {
          return comparisons?.filter(comparison => 
            !comparison.boolean_expressions && 
            !comparison.is_integer && 
            !comparison.is_string
          )[0];
        }
    };
    
    const getFilteredComparisons = (param) => {
      if (param.parameter === 'total_sum') {
        return comparisons?.filter(comparison => comparison.is_integer === true);
      } else if (param.parameter === 'purpose_of_payment' || param.parameter === 'comment') {
        return comparisons?.filter(comparison => comparison.is_string === true);
      } else {
        return comparisons?.filter(comparison => 
          !comparison.boolean_expressions && 
          !comparison.is_integer && 
          !comparison.is_string
        );
      }
    };

    const getAvailableParameters = (ruleIndex) => {
      const usedParameters = newRuleGroup.rules[ruleIndex].parameters.map(param => param.parameter);
      return parameters.filter(param => !usedParameters.includes(param.key));
    };

    const handleComparisonChange = (comparisonId) => {
      setNewRuleGroup(prev => ({ ...prev, type_of_comparison: comparisonId }));
      setErrors(prev => ({ ...prev, type_of_comparison: undefined }));
    };

    const getInputComponent = (param, ruleIndex, paramIndex) => {
      const searchableSelectParams = {
        company_account: {
          endpoint: '/api/v1/bank/bankaccounts/search/',
          placeholder: 'Выберите счет организации'
        },
        document_type: {
          endpoint: '/api/v1/bills/document-types/search/',
          placeholder: 'Выберите тип документа'
        },
        type_of_operation: {
          endpoint: '/api/v1/bills/operation-types/search/',
          placeholder: 'Выберите тип операции'
        },
        counterparty: {
          endpoint: '/api/v1/bills/counterparty/search/',
          placeholder: 'Выберите контрагента'
        },
        counterparty_account: {
          endpoint: '/api/v1/bank/counterparty-accounts/search/',
          placeholder: 'Выберите счет контрагента'
        }
      };

      if (param.parameter in searchableSelectParams) {
        const { endpoint, placeholder } = searchableSelectParams[param.parameter];
        return (
          <div className="w-100 me-2">
          <APISearchableSelect
            endpoint={endpoint}
            value={param.value}
            onChange={(value) => handleParameterChange(ruleIndex, paramIndex, 'value', value)}
            placeholder={placeholder}
            size=""
          />
          </div>
        );
      } else if (param.parameter === 'total_sum') {
        return (
          <Form.Control 
            className="me-2"
            placeholder="Выберите значение" 
            type="number"
            value={param.value} 
            onChange={(e) => handleParameterChange(ruleIndex, paramIndex, 'value', e.target.value)}
            isInvalid={!!errors[`rule_${ruleIndex}_value_${paramIndex}`]}
          />
        );
      } else {
        return (
          <Form.Control 
            className="me-2"
            placeholder="Выберите значение" 
            value={param.value} 
            onChange={(e) => handleParameterChange(ruleIndex, paramIndex, 'value', e.target.value)}
            isInvalid={!!errors[`rule_${ruleIndex}_value_${paramIndex}`]}
          />
        );
      }
    };

    return (
      <>
          <Modal show={showModal} onHide={handleModalClose} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Создание нового правила авторазнесения</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="d-flex mb-3 justify-content-center">
                          <div className="w-50">
                              <Form.Label>Введите название правила *</Form.Label>
                              <Form.Control
                                  type="text"
                                  size="sm"
                                  name="name"
                                  value={newRuleGroup.name}
                                  onChange={handleChange}
                                  isInvalid={!!errors.name}
                                  required
                              />
                              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                          </div>
                        </Form.Group>
                        <div className="row">
                            <div className="col">
                                <div className="container border border-secondary mb-5 p-3">
                                    <h4>Если:</h4>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="d-flex align-items-center flex-fill me-4">
                                        <label className='form-label me-3 fs-6'>Организация:</label>
                                        <div className="flex-fill">
                                        <APISearchableSelect
                                            endpoint="/api/v1/organization/search/"
                                            size =""
                                            name = "company"
                                            onChange={(value) => handleChange({ target: { name: "company", value } })}
                                            placeholder="Выберите организацию *"
                                            value={newRuleGroup?.company}
                                        />
                                        {errors.company && <p className="font-size-sm text-danger">{errors.company}</p>}
                                        </div>
                                        </div>
                                        <Form.Group className="d-flex align-items-center">
                                        {errors.type_of_comparison && <div className="text-danger me-2">{errors.type_of_comparison}</div>}
                                            {comparisons?.filter(comparison => comparison.boolean_expressions === true).map((comparison) => (
                                              <Form.Check
                                                key={comparison.id}
                                                type="radio"
                                                size="sm"
                                                name="type_of_comparison"
                                                label={comparison.name}
                                                className="me-2"
                                                checked={newRuleGroup.type_of_comparison === comparison.id}
                                                onChange={() => handleComparisonChange(comparison.id)}
                                                isInvalid={!!errors.type_of_comparison}
                                                required
                                            />
                                            ))}
                                        </Form.Group>
                                    </div>
                                    <div className="row">
                                    {newRuleGroup.rules.map((rule, ruleIndex) => (
                                        <div key={rule.id} className="border p-2 mb-2">
                                            <h6>Правило {ruleIndex + 1}</h6>
                                            {rule.parameters.map((param, paramIndex) => (
                                            <div key={paramIndex} className="d-flex mb-2 align-items-center">
                                                <Dropdown className="me-2">
                                                <Dropdown.Toggle variant="outline-secondary">
                                                    {parameters.find(p => p.key === param.parameter)?.label || 'Выберите параметр*'}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {getAvailableParameters(ruleIndex).map(p => (
                                                        <Dropdown.Item 
                                                            key={p.key}
                                                            onClick={() => {
                                                                handleParameterChange(ruleIndex, paramIndex, 'parameter', p.key);
                                                                handleParameterChange(ruleIndex, paramIndex, 'comparison', null);
                                                            }}
                                                        >
                                                            {p.label}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                                </Dropdown>
                                                <Dropdown className="me-2">
                                                <Dropdown.Toggle variant="outline-secondary">
                                                    {param.comparison
                                                    ? param.comparison.name 
                                                    : (() => {
                                                        const defaultComparison = getDefaultComparison(param);
                                                        
                                                        if (!param.comparison && defaultComparison) {
                                                            handleParameterChange(ruleIndex, paramIndex, 'comparison', defaultComparison);
                                                        }

                                                        return defaultComparison?.name || 'Нет доступных сравнении';
                                                        })()
                                                    }
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {getFilteredComparisons(param)?.map((comparison) => (
                                                    <Dropdown.Item onClick={() => handleParameterChange(ruleIndex, paramIndex, 'comparison', comparison)} key={comparison.id}>
                                                        {comparison.name}
                                                    </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                                </Dropdown>
                                                {getInputComponent(param, ruleIndex, paramIndex)}
                                                <i className="bi bi-trash3 p-0" style={{cursor: 'pointer'}} onClick={() => removeParameter(ruleIndex, paramIndex)}></i>
                                            </div>
                                            ))}
                                            <div className="d-flex align-items-center">
                                              <p className="text-success p-0" style={{cursor: 'pointer'}} onClick={() => addParameter(ruleIndex)}>+ Добавить параметр</p>
                                              {newRuleGroup.rules.length > 1 && (
                                              <p className="text-danger p-0 ms-2" style={{cursor: 'pointer'}} onClick={() => removeRule(ruleIndex)}>Удалить правило</p>
                                              )}
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                    <div className="d-flex align-items-center">
                                    <p className="text-success p-0 fs-6" style={{cursor: 'pointer'}} onClick={addRule}>+ Добавить правило</p>
                                </div>
                                </div>
                                <div className="container border border-secondary mb-3 p-3">
                                    <div>
                                        <h3>То заполнить данные</h3>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <Form.Group className="mb-3 flex-fill">
                                            <Form.Label>Проект</Form.Label>
                                            <div className="d-flex w-75 align-items-center">
                                                <div className="w-50">
                                                    <APISearchableSelect
                                                        endpoint={'api/v1/organization/projects/search/'}
                                                        value={newRuleGroup?.project}
                                                        onChange={(value) => handleChange({ target: { name: "project", value } })}
                                                        placeholder={'Выберите проект'}
                                                        size=""
                                                    />
                                                </div>
                                            </div>
                                            {errors.project && <p className="font-size-sm text-danger">{errors.project}</p>}
                                        </Form.Group>
                                        <Form.Group className="mb-3 flex-fill">
                                            <Form.Label>Статья затрат</Form.Label>
                                            <div className="d-flex w-75 align-items-center">
                                                <div className="w-50">
                                                    <APISearchableSelect
                                                        endpoint={'api/v1/bills/caption/search/'}
                                                        value={newRuleGroup?.caption}
                                                        onChange={(value) => handleChange({ target: { name: "caption", value } })}
                                                        size=""
                                                        placeholder={'Выберите статью затрат'}
                                                    />
                                                </div>
                                            </div>
                                            {errors.caption && <p className="font-size-sm text-danger">{errors.caption}</p>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="mt-auto mb-5">
                                    <p className={isSwitchOn ? 'text-primary' : 'text-secondary'}>Применить правило к существующим документам</p>
                                    <Form.Group className="d-flex w-75 align-items-center">
                                    <div className='form-switch'>
                                        <input 
                                            class="form-check-input" 
                                            type="checkbox" 
                                            role="switch" 
                                            checked={isSwitchOn}
                                            onChange={handleSwitchChange}/></div>
                                        <Form.Control 
                                            type="date"
                                            size="sm"
                                            value={newRuleGroup?.start_date}
                                            name="start_date"
                                            onChange={handleChange}
                                            disabled={!isSwitchOn}/>
                                        <span>—</span>
                                        <Form.Control 
                                            type="date"
                                            size="sm"
                                            value={newRuleGroup?.end_date}
                                            name="end_date"
                                            onChange={handleChange}
                                            disabled={!isSwitchOn}/>
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button 
                        variant="secondary" 
                        className="me-3" 
                        onClick={handleModalClose}
                        disabled={isSaving}
                    >
                        Отмена
                    </Button>
                    <Button 
                        variant="success" 
                        className="ms-3" 
                        onClick={handleSubmit}
                        disabled={isSaving}
                    >
                        {isSaving ? (
                            <div className="d-flex align-items-center">
                                <span className="me-2">Сохранение</span>
                                <ReactLoading 
                                    type="spin" 
                                    color="#FFFFFF" 
                                    height={16} 
                                    width={16} 
                                />
                            </div>
                        ) : 'Сохранить'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateRuleModal;