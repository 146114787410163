import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../styles/loginStyle.css";
import logo from '../assets/pp_logo.png'
import image from '../assets/login_image.png'
import axios from 'axios';
import ReactLoading from 'react-loading';
import { useAuth } from '../AuthContext';

const LoginForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth();
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // Clear error when user starts typing
    if (errors[e.target.name]) {
      setErrors(prev => ({ ...prev, [e.target.name]: '' }));
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = Object.keys(formData).reduce((acc, key) => {
      if (!formData[key]) {
        acc[key] = 'Заполните поле';
      }
      return acc;
    }, {});

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post('https://expansion-hub.com/api/v1/auth/', formData, setTimeout(5000));
      console.log('Login successful:', response.data);
      login({
        access: response.data.access,
        refresh: response.data.refresh,
        permissions: response.data.permissions,
        groups: response.data.groups,
        is_superuser: response.data.is_superuser
      });
      setLoading(false);
      navigate('/')
    } catch (error) {
      console.error('Login failed:', error.response.data);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="position-absolute top-50 start-50 translate-middle">
        <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
      </div>
    );
  }

  return (
    <div className="login_page_con">
      <div className="login_container">
        <div className="login_header">
          <img src={logo} alt="pplogo"></img>
          <Link to="/register" className='btn btn-outline-primary'>Регистрация</Link>
        </div>
        <div className="login_main">
          <h2>Авторизация</h2>
          <form onSubmit={handleSubmit}>
            <div className='mb-3'>
              <label className='form-label'>Логин:</label>
              <input
                className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                type="text"
                name="username"
                value={formData.username}
                placeholder='Введите ИИН'
                onChange={handleInputChange}
              />
              {errors.username && <span className="invalid-feedback">{errors.username}</span>}
            </div>
            <div className='mb-3'>
              <label className='form-label'>Пароль:</label>
              <div className={`input-group ${errors.password ? 'is-invalid' : ''}`}>
                <input
                  className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  placeholder='********'
                  onChange={handleInputChange}
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={togglePasswordVisibility}
                >
                  <i className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                </button>
              </div>
              {errors.password && <span className="invalid-feedback">{errors.password}</span>}
            </div>
            <button type="submit" className="btn btn-primary w-100">Войти</button>
          </form>
        </div>
      </div>
      <div className="image_container">
        <img src={image} alt="login_image"></img>
      </div>
    </div>
  );
};

export default LoginForm;