// Styles
import "../styles/analytics.css"

// React
import { useState, useEffect } from "react";

// Components
import SideBar from "./SideBar";
import Header from "./Header";

// Charts
import SemiCircleChart from "./analytics/SemiCircleChart";
import {Doughnut, Line} from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import PageHeading from "./ui/PageHeading/PageHeading";
import Card from "./ui/Card/Card";
import Button from "./ui/Button/Button";

// Main Component
function Analytics() {
    const [incomeMax, setIncomeMax] = useState(0);
    const [incomeMin, setIncomeMin] = useState(1);
    const [incomeValue, setIncomeValue] = useState(0);

    useEffect(() => {
        function runAnimation() {
            setIncomeMax(7000000)
            let currentMin = 1;
            let currentMax = 5000000;

            const increment = 50000;
            const interval = 10;

            // Функция анимации
            const animate = () => {
                if (currentMin < currentMax) {
                    currentMin += increment;
                    setIncomeMin(currentMin);
                    setIncomeValue(currentMin);
                    setTimeout(animate, interval)
                } else {
                    setIncomeMin(currentMax)
                }
            };

            animate()
        }

        runAnimation();
    }, [])

    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar current={"/analytics"}/>
                <div className="col py-3">
                    <PageHeading>PRO-отчеты</PageHeading>

                    <div className="Analytics__Period">
                        <div className="Analytics__PeriodHeading">
                            Период
                        </div>
                        <div className="Analytics__PeriodBody">
                            <input type="date" />
                            <span> - </span>
                            <input type="date" />

                            <Button bType="primary" size="medium">
                                Применить
                            </Button>
                        </div>
                    </div>

                   <div className="Analytics__Row">
                       <div className="Analytics__Col">
                           <Card style={{
                               marginBottom: "10px",
                           }}>
                               <div className="Analytics__CardHeader">
                                   Доходы
                               </div>

                               <SemiCircleChart min={incomeMin} max={incomeMax} value={incomeValue} />
                           </Card>

                           <Card style={{
                               marginBottom: "10px",
                           }}>
                               <div className="Analytics__CardHeader">
                                   Расходы
                               </div>

                               <SemiCircleChart min={incomeMin} max={incomeMax} value={incomeValue} />
                           </Card>

                           <Card style={{
                               marginBottom: "10px",
                           }}>
                               <div className="Analytics__CardHeader">
                                   Остатки
                               </div>

                               <div className="Analytics__Balances">
                                   <div className="Analytics__Balance">
                                       <span>USD: </span> 1 млн
                                   </div>
                                   <div className="Analytics__Balance">
                                       <span>KZT: </span> 100 млн
                                   </div>
                               </div>
                           </Card>
                       </div>
                       <div className="Analytics__Col--center">
                           <Card style={{
                               marginBottom: "10px",
                           }}>
                               <div className="Analytics__CardHeader">
                                   Доходы
                               </div>

                               <Bar options={{
                                   responsive: true,
                                   plugins: {
                                       legend: {
                                           display: false,
                                       },
                                       title: {
                                           display: false
                                       },
                                   },
                               }} data={{
                                   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                                   datasets: [
                                       {
                                           label: 'Dataset 1',
                                           data: [1, 2, 3, 4, 2, 4, 8, 10, 3, 5, 10, 11],
                                           backgroundColor: 'rgba(25,25,181,0.6)',
                                       }
                               ]}} />
                           </Card>

                           <Card style={{
                               marginBottom: "10px",
                           }}>
                               <div className="Analytics__CardHeader">
                                   Cashflow
                               </div>

                               <Bar options={{
                                   responsive: true,
                                   plugins: {
                                       legend: {
                                           display: false,
                                       },
                                       title: {
                                           display: false
                                       },
                                   },
                               }} data={{
                                   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                                   datasets: [
                                       {
                                           label: 'Dataset 1',
                                           data: [[1, -1], [10, -3], [5, -3], [10, -11], [7, -2], [0, -3], [5, -2], [8, -1], [4, -8], [10, -3], [10, -3], [10, -3]],
                                           backgroundColor: ["rgba(25,25,181,0.6)", "rgba(25,25,181,0.8)"],
                                       }
                                   ]}} />
                           </Card>
                       </div>
                       <div className="Analytics__Col" style={{
                           width: "300px",
                           minWidth: "300px"
                       }}>
                           <Card style={{
                               marginBottom: "10px",
                           }}>
                               <div className="Analytics__CardHeader">
                                   Налоги
                               </div>

                               <Doughnut options={{
                                   responsive: true,
                                   plugins: {
                                       legend: {
                                           display: false,
                                       }
                                   }
                               }} data={{
                                   labels: ['1', '2'],
                                   datasets: [
                               {
                                   label: ' ',
                                   data: [12, 19],
                                   backgroundColor: [
                                   '#40c740',
                                   'rgba(25,25,181,1)',
                                   ],
                                   borderColor: [
                                   '#40c740',
                                       'rgba(25,25,181,1)',
                                   ],
                                   borderWidth: 1,
                               },
                                   ],
                               }} />
                           </Card>

                           <Card style={{
                               marginBottom: "10px",
                           }}>
                               <div className="Analytics__CardHeader">
                                   Депозиты
                               </div>

                               <div className="Analytics__Deposit">
                                   10%
                               </div>
                           </Card>
                       </div>
                   </div>
                </div>
            </div>
        </div>
    );
}

export default Analytics;
