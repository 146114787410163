import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../styles/registerStyle.css";
import logo from '../assets/pp_logo.png'
import image from '../assets/login_image.png'
import axios from 'axios';
import ReactLoading from 'react-loading';

const RegistrationForm = () => {
  // State variables for form fields
  const [formData, setFormData] = useState({
    username: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    email: '',
    phone_number: '',
    password: '',
    confirmPassword: '',
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // State variable for error messages
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isUsernameValid, setIsUsernameValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  const checkUsername = async (username) => {
    if (!username.trim()) {
      setIsUsernameValid(false);
      setErrors((prev) => ({
        ...prev,
      }));
      return;
    }
  
    try {
      setIsCheckingUsername(true);
      const response = await api.get(`/api/v1/username/search/?search=${username}`);
      const usernameExists = response.data.exists;
  
      setIsUsernameValid(!usernameExists);
  
      if (usernameExists) {
        setErrors((prev) => ({
          ...prev,
          username: 'Данный пользователь зарегистрирован в системе',
        }));
      } else {
        setErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors.username;
          return newErrors;
        });
      }
    } catch (error) {
      console.error('Error checking username:', error);
      setErrors((prev) => ({
        ...prev,
        username: 'Ошибка при проверке логина',
      }));
    } finally {
      setIsCheckingUsername(false);
    }
  };

  const checkEmail = async (email) => {
    if (!email.trim()) {
      setIsEmailValid(false);
      setErrors((prev) => ({
        ...prev,
      }));
      return;
    }
  
    try {
      setIsCheckingEmail(true);
      const response = await api.get(`/api/v1/email/search/?search=${email}`);
      const emailExists = response.data.exists;
  
      setIsEmailValid(!emailExists);
  
      if (emailExists) {
        setErrors((prev) => ({
          ...prev,
          email: 'Данный email зарегистрирован в системе',
        }));
      } else {
        setErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors.email;
          return newErrors;
        });
      }
    } catch (error) {
      console.error('Error checking email:', error);
      setErrors((prev) => ({
        ...prev,
        username: 'Ошибка при проверке email',
      }));
    } finally {
      setIsCheckingEmail(false);
    }
  };

  const debouncedCheckUsername = debounce(checkUsername, 500);
  const debouncedCheckEmail = debounce(checkEmail, 500);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // Clear error when user starts typing
    if (errors[e.target.name]) {
      setErrors(prev => ({ ...prev, [e.target.name]: '' }));
    }
    if (name === 'username') {
      if (value.length >= 3) {
          debouncedCheckUsername(value);
      }
    }
    if (name === 'email') {
        if (value.length >= 3) {
            debouncedCheckEmail(value);
        }
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = Object.keys(formData).reduce((acc, key) => {
      if (!formData[key]) {
        acc[key] = 'Заполните поле';
      }
      return acc;
    }, {});

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // Submit the form
    try {
      setLoading(true);
      const response = await axios.post('https://expansion-hub.com/api/v1/worker/', formData, setTimeout(5000));
      console.log('Registration successful:', response.data);
      localStorage.setItem('access', response.data.access);
      localStorage.setItem('refresh', response.data.refresh);
      setLoading(false);
      navigate('/')
    } catch (error) {
      console.error('Registration failed:', error.response.data);
      // Handle error (e.g., show error message)
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (<div className="position-absolute top-50 start-50 translate-middle">
    <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
    </div>);
}

  return (
    <div className='container_reg'>
      <div className='registration_card'>
        <div className="registration_header">
          <img src={logo} alt="pplogo"></img>
          <Link to="/login" className='btn btn-outline-primary'>Авторизация</Link>
      </div>
        <div className='registration_main'>
          <h2>Регистрация</h2>
          <form onSubmit={handleSubmit} className="registration_form">
            <div className='form_input'>
              <div className="form_column">
              <div className='mb-3'>
                  <label className='form-label'>Email*</label>
                    <div className="input-group">
                    <input
                        className={`form-control ${errors.email ? 'is-invalid' : isEmailValid ? 'is-valid' : ''}`}
                        type="text"
                        name="email"
                        placeholder="Введите email"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                    {isCheckingEmail && (
                        <div className="input-group-append">
                        <span className="input-group-text">
                            <ReactLoading type="spin" color="#0000FF" height={20} width={20} />
                        </span>
                        </div>
                    )}
                    </div>
                    {errors.email && <div><span className="invalid-feedback d-block">{errors.email}</span></div>}

                </div>
                <div className='mb-3'>
                  <label className='form-label'>ИИН*</label>
                    <div className="input-group">
                    <input
                        className={`form-control ${errors.username ? 'is-invalid' : isUsernameValid ? 'is-valid' : ''}`}
                        type="text"
                        name="username"
                        placeholder="Введите ИИН"
                        value={formData.username}
                        onChange={handleInputChange}
                    />
                    {isCheckingUsername && (
                        <div className="input-group-append">
                        <span className="input-group-text">
                            <ReactLoading type="spin" color="#0000FF" height={20} width={20} />
                        </span>
                        </div>
                    )}
                    </div>{errors.username && <div><span className="invalid-feedback d-block">{errors.username}</span></div>}
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Фамилия*</label>
                  <input className={`form-control ${errors.first_name ? 'is-invalid' : ''}`} type="text" name="first_name" placeholder='Введите фамилию' value={formData.first_name} onChange={handleInputChange} />
                  {errors.first_name && <span className="invalid-feedback">{errors.first_name}</span>}
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Имя*</label>
                  <input className={`form-control ${errors.last_name ? 'is-invalid' : ''}`} type="text" name="last_name" placeholder='Введите имя' value={formData.last_name} onChange={handleInputChange} />
                  {errors.last_name && <span className="invalid-feedback">{errors.last_name}</span>}
                </div>
              </div>
              <div className="form_column">
              <div className='mb-3'>
                  <label className='form-label'>Отчество*</label>
                  <input className={`form-control ${errors.middle_name ? 'is-invalid' : ''}`} type="text" name="middle_name" placeholder='Введите отчество' value={formData.middle_name} onChange={handleInputChange} />
                  {errors.middle_name && <span className="invalid-feedback">{errors.middle_name}</span>}
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Номер телефона*</label>
                  <input className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`} type="text" name="phone_number" placeholder='+7 777 77 77 777' value={formData.phone_number} onChange={handleInputChange} />
                  {errors.phone_number && <span className="invalid-feedback">{errors.phone_number}</span>}
                </div>
               
                <div className='mb-3'>
                  <label className='form-label'>Пароль*</label>
                  <div className={`input-group ${errors.password ? 'is-invalid' : ''}`}>
                    <input
                      className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={formData.password}
                      placeholder='********'
                      onChange={handleInputChange}
                    />
                    <button
                      className="btn btn-outline-secondary password-toggle"
                      type="button"
                      onClick={togglePasswordVisibility}
                    >
                      <i className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                    </button>
                  </div>
                  {errors.password && <span className="invalid-feedback">{errors.password}</span>}
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Повторить пароль*</label>
                  <div className={`input-group ${errors.confirmPassword ? 'is-invalid' : ''}`}>
                    <input
                      className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                      type={showPassword ? "text" : "password"}
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      placeholder='********'
                      onChange={handleInputChange}
                    />
                    <button
                      className="btn btn-outline-secondary password-toggle"
                      type="button"
                      onClick={togglePasswordVisibility}
                    >
                      <i className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                    </button>
                  </div>
                  {errors.confirmPassword && <span className="invalid-feedback">{errors.confirmPassword}</span>}
                </div>
              </div>
            </div>
            <button type="submit" className="submit-button">Зарегистрироваться</button>
          </form>
        </div>
      </div>
      <div className='image_card'>
        <img src={image} alt="login_image"></img>
      </div>
    </div>
  );
};

export default RegistrationForm;
