import SideBar from "../SideBar";
import { useEffect, useState } from "react";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../functions/UprData";
import { CaptionForm } from "../captions/CaptionForm";
import APISearchableSelect from "./SearchableSelect";
import "../../styles/createPayment.css"
import Header from "../Header";
import ReactLoading from 'react-loading'
import { Alert } from 'react-bootstrap';

const CreatePayment = () => {
    const [formData, setFormData] = useState({
        decipher: [],
        origin: '',
        number: '',
        date: '',
        type_of_operation: '',
        total_sum: 0,
        commission_sum: 0,
        purpose_of_payment: '',
        comment: '',
        company: '',
        document_type: '',
        currency: '',
        company_account: '',
        counterparty: '',
        counterparty_account: '',
        project: '',
        caption: '',
        agreement: ''
      });
    
    const [captions, setCaptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currencies, setCurrencies] = useState([]);
    const [showDecipher, setShowDecipher] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [caption_types, setCaptionTypes] = useState([]);
    const navigate = useNavigate()

    const fetchCaptionTypes = useFetchData('/api/v1/bills/caption-types/', setCaptionTypes);

    const fetchCaption = useFetchData('api/v1/bills/caption/', setCaptions);

    const fetchCurrency = useFetchData('/api/v1/bank/currencies/', setCurrencies);

    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchCaption(),
                fetchCurrency(),
                fetchCaptionTypes(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };

    const handleDecipherChange = (index, e) => {
        const currentTotal = formData.decipher.reduce((acc, param, i) => {
            return i === index ? acc : acc + (param.sum || 0);
        }, 0);

        const newSum = parseFloat(e.value) || 0;
        const newTotal = currentTotal + newSum;

        if (e.name === "sum" && newTotal <= formData.total_sum) {
            setShowAlert(false);
        }

        const { name, value } = e;
        const updatedDecipher = formData.decipher.map((item, i) => {
            if (i === index) {
                const updatedItem = { ...item, [name]: value };
    
                if (name === "sum" || name === "nds_rate") {
                    updatedItem.nds_sum = (updatedItem.sum || 0) * (updatedItem.nds_rate || 0) / 100;
                }
    
                return updatedItem;
            }
            return item;
        });
        setFormData({
            ...formData,
            decipher: updatedDecipher
        });
    };

    const [decipherFields, setDecipherFields] = useState({
        sum: true,
        nds_sum: false,
        nds_rate: false,
        counterparty: false,
        caption: false,
        project: false,
        employee: false,
        agreement: false
      });

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(value);
    };

    const parseCurrency = (value) => {
        return parseFloat(value.replace(/\s+/g, '').replace(',', '.')) || 0;
    };


    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        if (name === 'counterparty') {
            handleChange({target:  { name: "counterparty", value: '' } });
            handleChange({target:  { name: "counterparty_account", value: '' } });
        } else if (name === 'caption') {
            handleChange({target:  { name: "caption", value: '' } });
        } else if (name === 'project') {
            handleChange({target:  { name: "project", value: '' } });
        } else if (name === 'agreement') {
            handleChange({target:  { name: "agreement", value: '' } });
        }
        setDecipherFields({
            ...decipherFields,
            [name]: checked
        });
    };

    const addDecipher = () => {
        setFormData({
          ...formData,
          decipher: [
            ...formData.decipher,
            {
              sum: 0,
              nds_sum: 0,
              nds_rate: 0,
              employee: null,
              counterparty: null,
              caption: '',
              project: '',
              accountable: null,
              agreement: null
            }
          ]
        });
      };


      const removeDecipher = (index) => {
        setFormData({
          ...formData,
          decipher: formData.decipher.filter((_, i) => i !== index)
        });
      };

      if (loading) {
        return (<div className="position-absolute top-50 start-50 translate-middle">
        <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
        </div>);
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const decipherSumTotal = formData.decipher.reduce((sum, param) => {
            const parsedSum = parseFloat(param.sum) || 0; // Ensure the sum is a number
            return sum + parsedSum;
        }, 0);

        if (decipherSumTotal > formData.total_sum) {
            setShowAlert(true);
            return;
        }
        try {
          const response = await api.post('/api/v1/bills/payment-create/', formData, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          console.log('Response:', response.data);
          navigate('/payment-registry')
        } catch (error) {
            alert('Заполните все поля')
            console.error('Error:', error);
        }
      };

    const handleShowDecipher = (checkbox) => {
        if (checkbox === true) {
            addDecipher();
            setShowDecipher(true);
        } else {
            setFormData({
                ...formData,
                decipher: []
            });
            setDecipherFields({
                sum: true,
                nds_sum: false,
                nds_rate: false,
                counterparty: false,
                caption: false,
                project: false,
                employee: false,
                agreement: false
              });
            setShowDecipher(false);
        }
    }

    return (
        <>
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-4">
                    <div className="create-header">
                        <h1>Добавление нового платежа</h1>
                    </div>
                    <div className="create-payment-content">
                        <form className="payment-form" onSubmit={handleSubmit}>
                            <div className='form_input'>
                                <div className="row g-3">
                                    <div className='col-md-12'>
                                    <label className='form-label'>Дата</label>
                                        <input 
                                            className="form-control form-control-sm"
                                            type="date" 
                                            name="date"
                                            value={formData.date} 
                                            onChange={handleChange} />
                                    </div>
                                    <div className='col-md-6'>
                                    <label className='form-label'>Тип документа</label>
                                        <APISearchableSelect
                                            endpoint="/api/v1/bills/document-types/search/"
                                            value={formData.document_type}
                                            onChange={(value) => handleChange({ target: { name: "document_type", value } })}
                                            placeholder="Выберите тип документа"
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                    <label className='form-label'>Вид операции</label>
                                        <APISearchableSelect
                                            endpoint="/api/v1/bills/operation-types/search/"
                                            value={formData.type_of_operation}
                                            onChange={(value) => handleChange({ target: { name: "type_of_operation", value } })}
                                            placeholder="Выберите вид операции"
                                        />                                   
                                    </div>
                                    <div className='col-md-6'>
                                    <label className='form-label'>Организация</label>
                                        <APISearchableSelect
                                            endpoint="/api/v1/organization/search/"
                                            value={formData.company}
                                            onChange={(value) => handleChange({ target: { name: "company", value } })}
                                            placeholder="Выберите организацию"
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                    <label className='form-label'>Счет организации</label>
                                        <APISearchableSelect
                                            endpoint="/api/v1/bank/bankaccounts/search/"
                                            value={formData.company_account}
                                            onChange={(value) => handleChange({ target: { name: "company_account", value } })}
                                            placeholder="Выберите счет организации"
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                    <label className='form-label'>Сумма</label>
                                        <input 
                                            className="form-control form-control-sm" 
                                            type="text" 
                                            name="total_sum" 
                                            placeholder='Введите сумму' 
                                            value={formatCurrency(formData.total_sum)} 
                                            onChange={(e) => handleChange({ target: { name: "total_sum", value: parseCurrency(e.target.value) } })}
                                        />                                    </div>
                                    <div className='col-md-4'>
                                    <label className='form-label'>Сумма комиссии</label>
                                        <input 
                                            className="form-control form-control-sm" 
                                            type="text" 
                                            name="commission_sum" 
                                            placeholder='Введите сумму' 
                                            value={formatCurrency(formData.commission_sum)} 
                                            onChange={(e) => handleChange({ target: { name: "commission_sum", value: parseCurrency(e.target.value) } })}
                                        />                                    
                                    </div>
                                    <div className='col-md-2'>
                                    <label className='form-label'>Валюта</label>
                                        <select
                                            className="form-select form-select-sm"
                                            value={formData.currency || ''}
                                            name="currency"
                                            onChange={handleChange}>
                                            <option value="">
                                                {formData.currency ? currencies.find(currency => currency.id === formData.currency)?.name : ''}
                                            </option>
                                            {currencies.map((currency) => (
                                                <option key={currency.id} value={currency.id}>
                                                    {currency.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={formData.decipher.length > 0 ? "d-none" : "col-md-6"}>
                                        <label className="form-label">Проект:</label>
                                            <APISearchableSelect
                                                endpoint="/api/v1/organization/projects/search/"
                                                value={formData.project || ''} 
                                                onChange={(value) => handleChange({target:  { name: "project", value } })}
                                                placeholder="Выберите проект"
                                            />
                                    </div>
                                    <div className={formData.decipher.length > 0 ? "d-none" : "col-md-6"}>
                                        <label className="form-label">Статья:</label>
                                            <APISearchableSelect
                                                endpoint="/api/v1/bills/caption/search/"
                                                value={formData.caption || ''} 
                                                onChange={(value) => handleChange({target:  { name: "caption", value } })}
                                                placeholder="Выберите статью"
                                            />
                                    </div>
                                    <div className={decipherFields.agreement ? "d-none" : 'col-md-6'}>
                                        <label className="form-label">Договор:</label>
                                            <APISearchableSelect
                                                endpoint={`/api/v1/organization/agreements/search/`}
                                                value={formData.agreement || ''}
                                                onChange={(value) => handleChange({target:  { name: "agreement", value } })}
                                                placeholder={"Выберите договор"}
                                            />
                                    </div>
                                    <div className="decipher-form">
                                        <div>
                                            <label className='form-label'>
                                                <input
                                                type="checkbox"
                                                className="from-control"
                                                checked={showDecipher}
                                                onChange={(e) => handleShowDecipher(e.target.checked)}
                                                />
                                                Разнести
                                            </label>
                                        </div>
                                        {showDecipher && (
                                            <div className="deciphercontent">
                                                <h6>Выберите параметр распределения</h6>
                                                <div className="params">
                                                <label className='me-2'>
                                                    <input
                                                    type="checkbox"
                                                    name="employee"
                                                    checked={decipherFields.employee}
                                                    onChange={handleCheckboxChange}
                                                    />
                                                    Физ. лицо
                                                </label>
                                                <label className='me-2'>
                                                    <input
                                                    type="checkbox"
                                                    name="counterparty"
                                                    checked={decipherFields.counterparty}
                                                    onChange={handleCheckboxChange}
                                                    />
                                                    Контрагент
                                                </label>
                                                <label>
                                                    <input
                                                    type="checkbox"
                                                    name="nds_rate"
                                                    checked={decipherFields.nds_rate}
                                                    onChange={handleCheckboxChange}
                                                    />
                                                    Ставка НДС
                                                </label>
                                                <label>
                                                    <input
                                                    type="checkbox"
                                                    name="agreement"
                                                    checked={decipherFields.agreement}
                                                    onChange={handleCheckboxChange}
                                                    />
                                                    Договор
                                                </label>
                                                </div>
                                                <div className="deciphers "> 
                                                {formData.decipher.map((decipher, index) => (
                                                <div key={index} className="decipher row py-3">
                                                    <div className="col-md-11 row">
                                                    {decipherFields && (
                                                        <div className="col-md-4">
                                                        <label className="form-label">Сумма:</label>
                                                        <input
                                                            type="text"
                                                            name="sum"
                                                            min="0"
                                                            step="any"
                                                            className="form-control form-control"
                                                            value={formatCurrency(decipher.sum)}
                                                            onChange={(e) => handleDecipherChange(index, {name: "sum", value: parseCurrency(e.target.value)})}
                                                            />
                                                        </div>
                                                    )}
                                                    {decipherFields.employee && (
                                                        <div className="col-md-4">
                                                        <label className="form-label">Физ. лицо:</label>
                                                            <APISearchableSelect
                                                                endpoint="/api/v1/bills/individual/search/"
                                                                size=""
                                                                value={decipher.employee || ''} 
                                                                onChange={(value) => handleDecipherChange(index, { name: "employee", value } )}
                                                                placeholder="Выберите сотрудника"
                                                            />
                                                        </div>
                                                    )}
                                                    {decipherFields.nds_rate && (
                                                        <div className="col-md-4">
                                                        <label className="form-label">Ставка НДС:</label>
                                                        <input
                                                            type="number"
                                                            name="nds_rate"
                                                            min="0"
                                                            step="any"
                                                            className="form-control form-control"
                                                            value={decipher.nds_rate}
                                                            onChange={(e) => handleDecipherChange(index, e.target)}
                                                        />
                                                        </div>
                                                    )}
                                                    {decipherFields.nds_rate && (
                                                        <div className="col-md-4">
                                                        <label className="form-label">Сумма НДС:</label>
                                                        <input
                                                            type="number"
                                                            min="0"
                                                            step="any"
                                                            name="nds_sum"
                                                            className="form-control form-control"
                                                            value={decipher.nds_sum || ''}
                                                            onChange={(e) => handleDecipherChange(index, e.target)}
                                                        />
                                                        </div>
                                                    )}
                                                    {decipherFields.counterparty && (
                                                        <div className="col-md-4">
                                                        <label className="form-label">Контрагент:</label>
                                                            <APISearchableSelect
                                                                endpoint="/api/v1/bills/counterparty/"
                                                                size=""
                                                                value={decipher.counterparty || ''} 
                                                                onChange={(value) => handleDecipherChange(index, { name: "counterparty", value } )}
                                                                placeholder="Выберите контрагента"
                                                            />
                                                        </div>
                                                    )}
                                                    <CaptionForm 
                                                        key={index} 
                                                        captions={captions} 
                                                        setCaptions={setCaptions}
                                                        param={decipher?.caption}
                                                        index={index}
                                                        handleSelectChange={handleDecipherChange}
                                                        caption_types={caption_types}
                                                        />
                                                    <div className="col-md-4">
                                                    <label className="form-label">Проект:</label>
                                                        <APISearchableSelect
                                                            endpoint="/api/v1/organization/projects/search/"
                                                            value={decipher.project || ''} 
                                                            size=""
                                                            onChange={(value) => handleDecipherChange(index, { name: "project", value } )}
                                                            placeholder="Выберите проект"
                                                        />
                                                    {decipherFields.agreement && (
                                                        <div className="col-md-4">
                                                        <label className="form-label">Договор:</label>
                                                            <APISearchableSelect
                                                                endpoint="/api/v1/organization/agreements/search/"
                                                                size=""
                                                                value={decipher.agreement || ''} 
                                                                onChange={(value) => handleDecipherChange(index, { name: "agreement", value } )}
                                                                placeholder="Выберите договор"
                                                            />
                                                        </div>
                                                    )}
                                                    </div>
                                                    </div>
                                                    <div className="col-md-1 d-flex align-items-center">
                                                        <i className="fs-5 bi bi-trash3" style={{cursor: 'pointer'}} onClick={() => removeDecipher(index)}></i>
                                                    </div>
                                    </div>
                                    ))}
                                    </div>
                                    <div className='d-flex mt-4'>
                                        <Alert show={showAlert} variant='danger' onClose={() => setShowAlert(false)} dismissible>
                                            <p>Общая сумма элементов превышает общую сумму платежа. Пожалуйста, скорректируйте суммы.</p>
                                        </Alert>
                                    </div>
                                    <button type="button" className="btn btn-primary" onClick={addDecipher}>Добавить платеж</button>
                                </div>
                            )}
                                    </div>
                                    <div className={decipherFields.counterparty ? "d-none" : "col-md-6"}>
                                    <label className='form-label'>Контрагент</label>
                                        <APISearchableSelect
                                            endpoint="/api/v1/bills/counterparty/search/"
                                            value={formData.counterparty}
                                            onChange={(value) => handleChange({ target: { name: "counterparty", value } })}
                                            placeholder="Выберите контрагента"
                                        />
                                    </div>
                                    <div className={decipherFields.counterparty ? "d-none" : "col-md-6"}>
                                    <label className='form-label'>Банковский счет контрагента</label>
                                        <APISearchableSelect
                                            endpoint={`/api/v1/bank/counterparty-accounts/search/?counterparty=${formData.counterparty}`}
                                            value={formData.counterparty_account}
                                            onChange={(value) => handleChange({ target: { name: "counterparty_account", value } })}
                                            placeholder="Выберите счет контрагента"
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                    <label className='form-label'>Назначение платежа</label>
                                    <input className="form-control form-control-sm" type="text" name="purpose_of_payment" placeholder='Назначение платежа' onChange={handleChange}/>
                                    </div>
                                    <div className='col-md-6'>
                                    <label className='form-label'>Комментарий</label>
                                    <input className="form-control form-control-sm" type="text" name="comment" placeholder='Напишите комментарий' onChange={handleChange}/>
                                    </div>
                                </div>
                            </div>

                            <button className="btn btn-primary" type="submit">Сохранить</button>
                        </form>
                    </div>
                </div>
                <div className="col"></div>
            </div>
        </div>
        </>
    );
}

export default CreatePayment;