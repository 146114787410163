import { useState, useEffect, useRef, useCallback  } from 'react';
import PaymentsTable from './PaymentsTable';
import api from '../../api';
import { Link } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from "../Header";
import { Modal } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import ProtectedContent from '../ProtectedContent';
import CreateRuleModal from './CreateRuleModal';
import EditRuleModal from './EditRuleModal';
import APIMultipleSearchableSelect from '../payments/SearchableTableMultiSelect'; 
import MultiSelectFieldStatus from './PaymentDefultStatus';
import PageHeading from "../ui/PageHeading/PageHeading";


const PaymentManagement = () => {
    const [purpose_of_payment, setPurposeFilter] = useState('');
    const [pay_status, setSelectedPayStatus] = useState([]);
    const [beforesum, setBeforeSum] = useState('');
    const [beforecommission_sum, setBeforeСommission_sum] = useState('');

    const [comments, setComFilter] = useState('');
    const [fromsum, setFromSum] = useState('');
    const [fromcommission_sum, setFromСommission_sum] = useState('');

    const [document_type, setSelectedDocType] = useState([]);
    const [opertype, setSelectedOperType] = useState([]);
    const [project, setSelectedProject] = useState([]); 
    const [caption, setSelectedCaption] = useState([]); 
    const [currencies, setSelectedCurrencies] = useState([]); 

    const [number_payment_document, setNumberOfPayDoc] = useState('');
    const [startDateFilterOne, setStartOneDateFilter] = useState('');
    const [startDateFilterTwo, setStartTwoDateFilter] = useState('');
    const [company, setCompanyChange] = useState('');
    const [counterparty, setcounterpartyChange] = useState('');
    const [bankaccounts, setBankaccountsChange] = useState('');
    const [counterparty_bankaccounts, setCounterpartyBankaccountsChange] = useState('');
    const [payments, setPayments] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const [openRuleModal, setOpenRuleModal] = useState(false);
    const [rules, setRules] = useState([]);
    const [selectedRuleId, setSelectedRuleId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const containerRef = useRef(null);
    const [errors, setErrors] = useState({});
    const [filtersVisible, setFiltersVisible] = useState(false);
    const limit = 25;

    
    const showModal = async() => {
        try {
            if (rules.length === 0) {
                setLoading(true);
                const response = await api.get('api/v1/bills/auto-spacing-rules/');
                if (response.status === 200) {
                    setRules(response.data);
            }}
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            setIsOpen(true);
        }
    };

    const handleRuleActivityChange = async (rule) => {
        try {
            const response = await api.patch(`api/v1/bills/auto-spacing-rule/${rule.id}/action/`, {'is_active': !rule.is_active});
            if (response.status === 200) {
                setRules((prevRules) => 
                    prevRules.map((b) => 
                        b.id === rule.id ? { ...b, is_active: !b.is_active } : b
                    )
                );
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleDeleteRule = async (rule) => {
        try {
            const response = await api.patch(`api/v1/bills/auto-spacing-rule/${rule.id}/action/`, {'is_deleted': true});
            if (response.status === 200) {
                setRules((prevRules) => 
                    prevRules.filter((b) => b.id !== rule.id)
                );
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleRuleDoubleClick = (ruleId) => {
        setSelectedRuleId(ruleId);
        setShowEditModal(true);
    };
  
    const hideModal = () => {
      setIsOpen(false);
    };

    const handleOpenModal = () => {
        setOpenRuleModal(true);
    }

    const handleReset = () => {
        // const search = location.search
        // navigate(location.search.replace(search,'')); 
        window.location.reload();
    };
    const handleSearch = async () => {
        try {
            // console.log(nameFilter,archivedFilterYES,archivedFilterNO,finModelFilter,startDateFilterOne,startDateFilterTwo)
            const response = await api.post('/api/v1/bills/paymentsfilter/', {
                purpose_of_payment: purpose_of_payment.toLowerCase(), 
                pay_status : pay_status, 
                document_type : document_type,
                startDateOne:startDateFilterOne,
                startDateTwo:startDateFilterTwo,
                company: company, //?
                counterparty:counterparty,
                bankaccounts:bankaccounts,
                payFromSum:fromsum,
                payBeforeSum:beforesum,
                payComm:comments, 
                    // -----
                paydocnumber: number_payment_document,
                opertype:opertype,
                project:project,
                caption:caption,
                currencies:currencies,
                payFromCommissionSum:fromcommission_sum,
                payBeforeCommissionSum:beforecommission_sum,
                counterparty_bankaccounts:counterparty_bankaccounts
               
            });
            console.log('project fetched:', response.data); 
            setPayments(response.data);
        } catch (error) {
            console.error('Error fetching project:', error);
        }
    };
   
    const fetchPayments = async (offset) => {
        setLoading(true);
        try {
            const response = await api.get(`api/v1/bills/payments/?limit=${limit}&offset=${offset}`);
            setPayments(prevPayments => {
                const newPayments = response.data.results.filter(newPayment => 
                    !prevPayments.some(prevPayment => prevPayment.id === newPayment.id)
                );
                return [...prevPayments, ...newPayments];
            });
            if (!response.data.next) {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching payments', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setPayments([]); // Clear existing payments
        setOffset(0); // Reset offset
        setHasMore(true);
    }, []);

    useEffect(() => {
        if (hasMore) {
            fetchPayments(offset);
        }
    }, [offset, hasMore]);


    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);
    useEffect(() => {
        // Вызываем фильтрацию каждый раз, когда изменяется состояние.
        handleSearch();
    }, [pay_status, document_type,project,caption,currencies,opertype,number_payment_document, startDateFilterOne, startDateFilterTwo, company, counterparty,counterparty_bankaccounts, bankaccounts, fromsum,fromcommission_sum,beforecommission_sum, beforesum, purpose_of_payment, comments]);
    


    return (
        <div className="container-fluid" translate="no" >
            <Header/>
            <div className="row flex-nowrap">
                <SideBar current="/payment-registry"/>
            {/* Фильтр */}
            <div className={`filters-panel ${filtersVisible ? 'visible' : ''}`}>
            <button className="btn-closeleft" onClick={() => setFiltersVisible(false)}>
                <img src="closeleft.png" alt="Closeleft" className="filter-icon-close" />
            </button>
            <PageHeading>Платежи</PageHeading>
            <div className="form-group">
                <label className='form-label'>Статус</label>
                <MultiSelectFieldStatus
                    value={pay_status}
                    onChange={(value) => setSelectedPayStatus(value)} // Только обновляем состояние
                />
            </div>
            <div className="form-group">
                <label className='form-label'>Тип документа</label>
                <APIMultipleSearchableSelect
                    endpoint="api/v1/bills/document-types/search/"
                    value={document_type}
                    onChange={(value) => setSelectedDocType(value)} // Только обновляем состояние
                    placeholder="Выберите тип документа"
                />
            </div>
            <div className="form-group">
                <label className='form-label'>Номер документа</label>
                <input
                    type="text"
                    placeholder="Введите номер документа"
                    value={number_payment_document}
                    onChange={e => setNumberOfPayDoc(e.target.value)} // Только обновляем состояние
                    className="form-control form-control-sm"
                />
            </div>
            <div className="form-group">
                <label className='form-label'>Вид операции</label>
                <APIMultipleSearchableSelect
                    endpoint="api/v1/bills/operation-types/search/"
                    value={opertype}
                    onChange={(value) => setSelectedOperType(value)} // Только обновляем состояние
                    placeholder="Выберите вид операции"
                />
            </div>
            <div className="form-group">
                <label className='form-label'>Проекты</label>
                <APIMultipleSearchableSelect
                    endpoint="/api/v1/organization/projects/search/"
                    value={project}
                    onChange={(value) => setSelectedProject(value)} // Только обновляем состояние
                    placeholder="Выберите проект"
                />
            </div>
            <div className="form-group">
                <label className='form-label'>Статья</label>
                <APIMultipleSearchableSelect
                    endpoint="api/v1/bills/caption/search/"
                    value={caption}
                    onChange={(value) => setSelectedCaption(value)} // Только обновляем состояние
                    placeholder="Выберите статью"
                />
            </div>
            <div className="form-group">
                <label className='form-label'>Валюта</label>
                <APIMultipleSearchableSelect
                    endpoint="api/v1/bank/currenciessearch/"
                    value={currencies}
                    onChange={(value) => setSelectedCurrencies(value)} // Только обновляем состояние
                    placeholder="Выберите валюту"
                />
            </div>
            <div className="form-group">
                <label className='form-label'>Дата создания</label>
                <div className="d-flex">
                    <span style={{ marginTop: '7px', marginRight: '7px' }}>с</span>
                    <input
                        type="date"
                        value={startDateFilterOne}
                        onChange={e => setStartOneDateFilter(e.target.value)} // Только обновляем состояние
                        className="form-control form-control-sm"
                    />
                    <span className="mx-2" style={{ marginTop: '7px' }}>—</span>
                    <span style={{ margin: '7px', marginRight: '7px' }}>до</span>
                    <input
                        type="date"
                        value={startDateFilterTwo}
                        onChange={e => setStartTwoDateFilter(e.target.value)} // Только обновляем состояние
                        className="form-control form-control-sm"
                    />
                </div>
            </div>
            <div className="form-group">
                <label>Сумма комиссии</label>
                <div className="d-flex gap-2">
                    <input
                        type="number"
                        value={fromcommission_sum}
                        onChange={e => setFromСommission_sum(e.target.value)} // Только обновляем состояние
                        className="form-control"
                        placeholder="От"
                    />
                    <input
                        type="number"
                        value={beforecommission_sum}
                        onChange={e => setBeforeСommission_sum(e.target.value)} // Только обновляем состояние
                        className="form-control"
                        placeholder="До"
                    />
                </div>
            </div>
            <div className="form-group">
                <label className='form-label'>Организация</label>
                <APIMultipleSearchableSelect
                    endpoint="/api/v1/organization/"
                    value={company}
                    onChange={(value) => setCompanyChange(value)} // Только обновляем состояние
                    placeholder="Выберите организацию"
                />
            </div>
            <div className="form-group">
                <label className='form-label'>Банковский счет организации</label>
                <APIMultipleSearchableSelect
                    endpoint="api/v1/bank/bankaccounts/search/"
                    value={bankaccounts}
                    onChange={(value) => setBankaccountsChange(value)} // Только обновляем состояние
                    placeholder="Выберите банковский счет организации"
                />
            </div>
            <div className="form-group">
                <label className='form-label'>Контрагент</label>
                <APIMultipleSearchableSelect
                    endpoint="api/v1/bills/counterparty/search/"
                    value={counterparty}
                    onChange={(value) => setcounterpartyChange(value)} // Только обновляем состояние
                    placeholder="Выберите организацию"
                />
            </div>
            <div className="form-group">
                <label className='form-label'>Банковский счет контрагента</label>
                <APIMultipleSearchableSelect
                    endpoint="api/v1/bank/counterparty-accounts/search/"
                    value={counterparty_bankaccounts}
                    onChange={(value) => setCounterpartyBankaccountsChange(value)} // Только обновляем состояние
                    placeholder="Выберите банковский счет контрагента"
                />
            </div>
            <div className="form-group">
                <label>Сумма платежа</label>
                <div className="d-flex gap-2">
                    <input
                        type="number"
                        value={fromsum}
                        onChange={e => setFromSum(e.target.value)} // Только обновляем состояние
                        className="form-control"
                        placeholder="От"
                    />
                    <input
                        type="number"
                        value={beforesum}
                        onChange={e => setBeforeSum(e.target.value)} // Только обновляем состояние
                        className="form-control"
                        placeholder="До"
                    />
                </div>
            </div>
            <div className="form-group">
                <label className='form-label'>Назначение платежа</label>
                <input
                    type="text"
                    placeholder="Введите назначение платежа"
                    value={purpose_of_payment}
                    onChange={e => setPurposeFilter(e.target.value)} // Только обновляем состояние
                    className="form-control form-control-sm"
                />
            </div>
            <div className="form-group">
                <label className='form-label'>Комментарий</label>
                <input
                    type="text"
                    placeholder="Введите комментарий платежа"
                    value={comments}
                    onChange={e => setComFilter(e.target.value)} // Только обновляем состояние
                    className="form-control form-control-sm"
                />
            </div>
            <button className="btn btn-outline-secondary w-100 apply" onClick={handleReset}>Сбросить</button>
        </div>
                <div className="col py-3">
                    <div className="payment-management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <h3>Платежи</h3>
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                    <div>
                                    <button className="show-filters-btn" onClick={() => setFiltersVisible(true)}><img src="setting.png" alt="Settings" className="filter-icon" /></button>
                                        <ProtectedContent perms={"payment.add_payment"}>
                                            <Link to='/create-payment' className='btn btn-success me-2'>Добавить платеж</Link>
                                        </ProtectedContent>
                                        <button onClick={() => setOffset(0)} className="btn btn-primary">Обновить</button>
                                        <ProtectedContent perms={"payment.view_autospacinggroup"}>
                                            <button className="btn btn-info ms-2" onClick={showModal}>Правила авторазнесения</button>
                                        </ProtectedContent>
                                    </div>
                                </div>
                                <div ref={containerRef} className="table-responsive" style={{ height: '75vh', overflowY: 'scroll', overflowX: 'scroll', width:'85vw'}}>
                                    <PaymentsTable payments={payments} />
                                </div>
                                {loading && 
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={isOpen} onHide={hideModal} dialogClassName={"modal-dialog-centered modal-dialog-scrollable"}>
                <Modal.Header>
                    <Modal.Title>Правила авторазнесения документов</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <div className='mt-3 mb-4'>
                            <p>Текущие правила</p>
                            <div>
                                <table className='table table-borderless table-sm'>
                                    <tbody>
                                        {rules.map((rule) => (
                                            <tr 
                                                key={rule.id} 
                                                onDoubleClick={() => handleRuleDoubleClick(rule.id)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <td className='w-10'>
                                                    <div className='form-switch'>
                                                        <input 
                                                            class="form-check-input" 
                                                            type="checkbox" 
                                                            role="switch"
                                                            checked={rule.is_active}
                                                            onChange={() => {handleRuleActivityChange(rule)}}/>
                                                    </div>
                                                </td>
                                                <td className='text-start'>{rule?.name}</td>
                                                <td><i className='bi bi-trash3' onClick={() => {handleDeleteRule(rule)}}></i></td>
                                            </tr>
                                        ))}                                        
                                    </tbody>
                                </table>
                            </div>
                            <button className='btn btn-success btn-sm mt-3' onClick={handleOpenModal}>+ Добавить новое правило</button>
                        </div>
                    </Modal.Body>
                {/* <Modal.Footer>
                <button onClick={hideModal}>Cancel</button>
                <button>Save</button>
                </Modal.Footer> */}
            </Modal>
            <CreateRuleModal 
                showModal={openRuleModal} 
                setShowModal={setOpenRuleModal} 
                rules={rules} 
                setRules={setRules}
            />
            <EditRuleModal 
                showModal={showEditModal}
                setShowModal={setShowEditModal}
                rules={rules}
                setRules={setRules}
                ruleId={selectedRuleId}
            />
        </div>
        
    );
};

export default PaymentManagement;
