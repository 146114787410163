import { useState, useEffect } from "react";
import api from "../api";
import SideBar from "./SideBar";
import ReactLoading from 'react-loading';
import Header from "./Header";
import { useNavigate, useParams } from 'react-router-dom';
import useFetchData  from '../functions/UprData.jsx'


const UserEditPage = () => {
    const { id } = useParams();
    const [groups, setGroups] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();


    const fetchUserData = async () => {
        try {
            setLoading(true);
            const response = await api.get(`api/v1/users/${id}/`);
            setUserData(response.data);
        } catch (error) {
            console.error('Error fetching user data', error);
        } finally {
            setLoading(false);
        }
    }

    const fetchRoles = useFetchData('api/v1/groups/', setGroups);

    useEffect(() => {
        setLoading(true);
        fetchUserData();
        fetchRoles();
        setLoading(false);
    },[id]);

    const handleInputChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleGroupInput = (groupId, isChecked) => {
        setUserData(prevForm => {
            let updatedGroups;
            if (isChecked) {
                updatedGroups = [...prevForm.groups, groupId];
            } else {
                updatedGroups = prevForm.groups.filter(id => id !== groupId);
            }
            return {
                ...prevForm,
                groups: updatedGroups
            };
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { username, first_name, last_name, phone_number, email, password, confirmPassword } = userData;
        const errors = {};
        if (!username) {
            errors.username = 'Username is required';
        }
        if (!email) {
            errors.email = 'Email is required';
        }
        if (password !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }
        if (!first_name) {
            errors.first_name = 'First name is required';
        }
        if (!last_name) {
            errors.last_name = 'Last name is required';
        }
        if (!phone_number) {
            errors.phone_number = 'Phone number is required'
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
        try {
            const response = api.put(`api/v1/users/${id}/update/`, userData);
            const updatedPayment = response.data;
            console.log('Updated user:', updatedPayment);
            navigate('/finmodel')
        } catch (error) {
            console.log(error)
        }
    }

    if (loading) {
        return (
            <div className="position-absolute top-50 start-50 translate-middle">
                <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
            </div>);
    }

    return (
        <>
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col">
                    <div className="edit-user-header mt-3">
                        <h2>Редактирование пользователя</h2>
                    </div>
                    <div className="edit-user-content mt-5">
                        <form className="user-form" onSubmit={handleSubmit}>
                            <div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-5">
                                            <label className='form-label'>Логин</label>
                                            <input className="form-control" type="text" name="username" placeholder='Введите ИИН' value={userData.username} onChange={handleInputChange} />
                                            {errors.username && <span>{errors.username}</span>}
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">Фамилия</label>
                                            <input className="form-control" type="text" name="first_name" placeholder='Введите фамилию' value={userData.first_name} onChange={handleInputChange} />
                                            {errors.first_name && <span>{errors.first_name}</span>}
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">Имя</label>
                                            <input className="form-control" type="text" name="last_name" placeholder='Введите имя' value={userData.last_name} onChange={handleInputChange} />
                                            {errors.last_name && <span>{errors.last_name}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-5">
                                            <label className="form-label">Отчество</label>
                                            <input className="form-control" type="text" name="middle_name" placeholder='Введите отчество' value={userData.middle_name} onChange={handleInputChange} />
                                            {errors.middle_name && <span>{errors.middle_name}</span>}
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">Номер телефона</label>
                                            <input className="form-control" type="text" name="phone_number" placeholder='+7 777 77 77 777' value={userData.phone_number} onChange={handleInputChange} />
                                            {errors.phone_number && <span>{errors.phone_number}</span>}
                                        </div>
                                        <div className="mb-5">
                                            <label className='form-label'>Email</label>
                                            <input className="form-control" type="email" name="email" placeholder='Введите email' value={userData.email} onChange={handleInputChange} />
                                            {errors.email && <span>{errors.email}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className='mb-5'>
                                            <label className='form-label'>Должность</label>
                                            <input className="form-control" type="text" name="position" placeholder='Введите должность' value={userData.position} onChange={handleInputChange} />
                                            {errors.position && <span>{errors.position}</span>}
                                        </div>
                                        <div className='mb-5'>
                                            <label className='form-label'>Новый пароль</label>
                                            <input className="form-control" type="password" name="password" value={userData.password} onChange={handleInputChange} />
                                            {errors.password && <span>{errors.password}</span>}
                                        </div>
                                        <div className='mb-5'>
                                            <label className='form-label'>Подтверждение нового пароля</label>
                                            <input className="form-control" type="password" name="confirmPassword" value={userData.confirmPassword} onChange={handleInputChange} />
                                            {errors.confirmPassword && <span>{errors.confirmPassword}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="groups col-md-4">
                                    <div className="card border-light bg-transparent mb-3">
                                        <div className="card-header bg-transparent">
                                            <h5 className="mb-2">Группы доступов</h5>
                                        </div>
                                        <div className="card-body">
                                        <div>
                                            {groups.map(group => (
                                            <div key={group.id} className="col-md-12">
                                                <div className="form-check">
                                                <input 
                                                        className="form-check-input" 
                                                        type="checkbox"
                                                        checked={userData.groups?.includes(group.id)} 
                                                        id={`group-${group.id}`} 
                                                        onChange={(e) => handleGroupInput(group.id, e.target.checked)}
                                                        />
                                                <label className="form-check-label" htmlFor={`group-${group.id}`}>
                                                    {group.name}
                                                </label>
                                                </div>
                                            </div>
                                            ))}
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary" type="submit">Сохранить</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default UserEditPage;