import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/paymentStyle.css";
import { useAuth } from "../../AuthContext";

const PaymentsTable = ({ payments }) => {
    const { permissions, user } = useAuth();
    const navigate = useNavigate();
    const [showSettings, setShowSettings] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState(() => {
        const savedColumns = localStorage.getItem("selectedColumns");
        return savedColumns ? JSON.parse(savedColumns) : [
            "Статус",
            "Документ",
            "Номер документа",
            "Дата платежа",
            "Организация",
            "Сумма",
        ];
    });

    const allColumns = [
        "Статус",
        "Документ",
        "Номер документа",
        "Виды операции",
        "Дата платежа",
        "Организация",
        "Сумма",
        "Банковский счет организации",
        "Контрагент",
        "Банковский счет контрагента",
        "Комментарий",
        "Назначение платежа",
        "НДС",
        "Валюта",
        "Проект",
        "Статья",
    ];

    useEffect(() => {
        localStorage.setItem("selectedColumns", JSON.stringify(selectedColumns));
    }, [selectedColumns]);

    const handleRowClick = (payment) => {
        if (!permissions.includes("payment.change_payment") && !user.is_superuser) {
            return;
        }
        navigate(`/edit-payment/${payment.id}`);
    };

    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const toggleColumn = (column) => {
        setSelectedColumns((prevColumns) =>
            prevColumns.includes(column)
                ? prevColumns.filter((col) => col !== column)
                : [...prevColumns, column]
        );
    };

    const selectAllColumns = () => {
        setSelectedColumns([...allColumns]);
    };

    const saveSettings = () => {
        setShowSettings(false);
    };

    const cancelSettings = () => {
        const savedColumns = localStorage.getItem("selectedColumns");
        setSelectedColumns(savedColumns ? JSON.parse(savedColumns) : []);
        setShowSettings(false);
    };

    const getStatusDisplay = (payment) => {
        const { is_spaced, is_auto_spaced } = payment;
        const bgColor = is_spaced ? "#75EA27" : "#FB7E24";
        const showA = is_auto_spaced;
        return (
            <div className="position-relative">
                <div
                    className="d-flex align-items-center justify-content-center rounded-circle"
                    style={{
                        backgroundColor: bgColor,
                        width: "16px",
                        height: "16px",
                        color: "white",
                        margin: "auto",
                    }}
                >
                    {showA && <span className="fw-bold" style={{ fontSize: "10px" }}>A</span>}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                {/* <h4>Платежи</h4> */}
                <button
                    className="btn btn-outline-secondary"
                    onClick={() => setShowSettings(true)}
                    style={{
                        borderRadius: "50%",
                        padding: "6px",
                        width: "36px",
                        height: "36px",
                    }}
                >
                    <i className="bi bi-gear-fill"></i>
                </button>
            </div>

            <table className="table table-striped mt-3">
                <thead className="table-light">
                    <tr>
                        {allColumns.map((column) => (
                            selectedColumns.includes(column) && <th key={column}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {payments.map((payment) => (
                        <tr key={payment.id} onDoubleClick={() => handleRowClick(payment)}>
                            {selectedColumns.includes("Статус") && <td>{getStatusDisplay(payment)}</td>}
                            {selectedColumns.includes("Документ") && <td>{payment.document_type?.name || ""}</td>}
                            {selectedColumns.includes("Номер документа") && <td>{payment.number || ""}</td>}
                            {selectedColumns.includes("Виды операции") && <td>{payment.type_of_operation?.name || ""}</td>}
                            {selectedColumns.includes("Дата платежа") && <td>{formatDate(payment.date)}</td>}
                            {selectedColumns.includes("Организация") && <td>{payment.company?.name || ""}</td>}
                            {selectedColumns.includes("Сумма") && <td>{new Intl.NumberFormat("ru-RU").format(payment.total_sum)}</td>}
                            {selectedColumns.includes("Банковский счет организации") && <td>{payment.company_account?.name || ""}</td>}
                            {selectedColumns.includes("Контрагент") && <td>{payment.counterparty?.name || ""}</td>}
                            {selectedColumns.includes("Банковский счет контрагента") && <td>{payment.counterparty_account?.name || ""}</td>}
                            {selectedColumns.includes("Комментарий") && <td>{payment.comment || ""}</td>}
                            {selectedColumns.includes("Назначение платежа") && <td>{payment.purpose_of_payment || ""}</td>}
                            {selectedColumns.includes("НДС") && <td>{payment.commission_sum || ""}</td>}
                            {selectedColumns.includes("Валюта") && <td>{payment.currency?.name || ""}</td>}
                            {selectedColumns.includes("Проект") && <td>{payment.project?.name || ""}</td>}
                            {selectedColumns.includes("Статья") && <td>{payment.caption?.name || ""}</td>}
                        </tr>
                    ))}
                </tbody>
            </table>

            {showSettings && (
                <div className="modal" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
                    <div className="modal-dialog" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header" style={{ borderBottom: "2px solid #eee" }}>
                                <h5 className="modal-title">Настройка отображения столбцов</h5>
                                <button
                                    className="btn-close"
                                    onClick={cancelSettings}
                                ></button>
                            </div>
                            <div className="modal-body" style={{ padding: "20px" }}>
                                <div className="container-fluid">
                                    <div className="row" style={{ gap: "10px", justifyContent: "space-evenly" }}>
                                        {allColumns.map((column) => (
                                            <div
                                                key={column}
                                                className={`col-4 p-2 d-flex align-items-center justify-content-start ${
                                                    selectedColumns.includes(column) ? "selected-column" : ""
                                                }`}
                                                onClick={() => toggleColumn(column)}
                                                style={{
                                                    cursor: "pointer",
                                                    border: selectedColumns.includes(column)
                                                        ? "2px solid #007BFF"
                                                        : "1px solid #ddd",
                                                    padding: "8px",
                                                    transition: "0.3s",
                                                    borderRadius: "5px",
                                                    width: "30%", // Увеличиваем ширину, чтобы уместить три элемента в ряд
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedColumns.includes(column)}
                                                    onChange={() => {}}
                                                    className="me-2"
                                                />
                                                <span
                                                    className={`column-label ${
                                                        selectedColumns.includes(column) ? "text-primary" : ""
                                                    }`}
                                                >
                                                    {column}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer" style={{ borderTop: "2px solid #eee" }}>
                                <button
                                    className="btn btn-outline-primary me-2"
                                    onClick={selectAllColumns}
                                >
                                    Выбрать все
                                </button>
                                <button
                                    className="btn btn-secondary me-2"
                                    onClick={cancelSettings}
                                >
                                    Отмена
                                </button>
                                <button className="btn btn-primary" onClick={saveSettings}>
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PaymentsTable;
