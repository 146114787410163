// import React, { useState, useEffect } from 'react';
import api from '../../api';
import { Link } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from "../Header";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useRef, useCallback  } from 'react';
import ReactLoading from 'react-loading';
import APIMultipleSearchableSelect from '../payments/SearchableTableMultiSelect';
import SearchableSelect from '../payments/SearchableSelect'; 
import ProtectedContent from '../ProtectedContent';
import { useAuth } from "../../AuthContext";
import { Modal} from 'react-bootstrap';
import PageHeading from "../ui/PageHeading/PageHeading";

const BankAccounts = () => {
    const { permissions, user } = useAuth();
    const [bankaccs, setBankAccounts] = useState([]);
    const [counterparties, setCounterparties] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [banks, setBanks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 25;
    const [checkedBankAccounts, setCheckedBankAccounts] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [nubmerFilter, setnumberFilter] = useState('');
    const [organFilter, setSelectedOrganization] = useState('');
    const [archivedFilterNotArchive, setArchivedFilterNotActive] = useState(false); //yes
    const [archivedFilterActive, setArchivedFilterActive] = useState(false);  //no
    const location = useLocation();
    const navigate = useNavigate();
    const searchIDofelem = location.search.replace("?","");
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const initialFormData = {
        name: '',
        code: '',
        account_type: '',
        account_number: '',
        company: null,
        bank: null,
        currency: null,
        owner_organization: null,
        owner_counterparty: null
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});

    const fetchBankAccounts = async (offset = 0, filters = {}) => {
        setLoading(true);
        try {
            if (searchIDofelem) {
                const response = await api.post('api/v1/bank/bankaccountsfilter/', {
                    id: searchIDofelem,
                    is_active: [true, false]
                });
                setBankAccounts(response.data);
                setTotalCount(response.data.length);
            } else {
                const response = await api.get(`api/v1/bank/allbankaccounts/?limit=${limit}&offset=${offset}`, {
                    name: nameFilter ? nameFilter.toLowerCase() : '',
                    number_of_check: nubmerFilter,
                    is_active: [!archivedFilterNotArchive, archivedFilterActive],
                    company: organFilter,
                    ...filters
                });

                const newBankAccounts = response.data.results;
                setBankAccounts(prev => 
                    offset === 0 ? newBankAccounts : [...prev, ...newBankAccounts]
                );
                setTotalCount(response.data.count);
                setHasMore(response.data.next !== null);
            }
        } catch (error) {
            console.error('Error fetching BankAccounts', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        Promise.all([
            fetchCounterparty(), 
            fetchBanks(), 
            fetchCompanies(), 
            fetchCurrency()
        ]).then(() => {
            fetchBankAccounts();
        });
    }, []);

    useEffect(() => {
        if (offset > 0) {
            fetchBankAccounts(offset);
        }
    }, [offset]);
    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchCounterparty(),
                fetchBanks(),
                fetchCompanies(),
                fetchCurrency(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => async () => {
        if (hasMore) {
            fetchBankAccounts(offset);
        }
        fetchData();
    }, [offset, hasMore]);
    
    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/v1/organization/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies', error)
        }
    }
    const fetchCurrency = async () => {
        try {
            const response = await api.get('/api/v1/bank/currencies/');
            setCurrency(response.data);
        } catch (error) {
            console.log('Error fetching document types', error)
        }
    }
    const fetchCounterparty = async () => {
        try {
            const res = await api.get('api/v1/bills/counterparty/');
            setCounterparties(res.data);
        } catch (error) {
            console.error("Error fetching counterpaties", error)
        }
    }
    
    const fetchBanks = async () => {
        try {
            const response = await api.get('/api/v1/bank/');
            setBanks(response.data);
        } catch (error) {
            console.error('Error fetching bank accounts', error)
        }
    }

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);
    useEffect(() => {
        const fetchBankAccountsById = async () => {
            if (searchIDofelem) {
                try {
                    const response = await api.post('api/v1/bank/bankaccountsfilter/', {
                        id:searchIDofelem,
                        is_active : [true,false]
                    });
                    if (response.data.length > 0) {
                        setNameFilter(response.data[0].name);
                        setBankAccounts(response.data);
                        fetchData();
                    }
                } catch (error) {
                    console.error('Error fetching BankAccounts by ID', error);
                }
            } else {
                setNameFilter('');
                setBankAccounts([]);
                setOffset(0);
                setHasMore(true);
                fetchBankAccounts();
            }
        };

        fetchBankAccountsById();
    }, [searchIDofelem]);

    const handleUpdateBankAccounts = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(checkedBankAccounts.map(id => 
                api.patch(`api/v1/bank/bankaccounts/${id}/update/`, { is_active: isActive })
            ));
            setBankAccounts([]);
            fetchBankAccounts();
            setOffset(0);
            setHasMore(true);
            setCheckedBankAccounts([])
        } catch (error) {
            console.error(`Error ${isActive ? 'unarchiving' : 'archiving'} BankAccounts`, error);
        } finally {
            setLoading(false);
        }
    };
    const handleReset = () => {
        const search = location.search
        navigate(location.search.replace(search,'')); 
        window.location.reload();
    };
    const handleArchiveBankAccounts = () => handleUpdateBankAccounts(false);
    const handleUnarchiveBankAccounts = () => handleUpdateBankAccounts(true);
    

    const handleCheckboxChange = (orgId) => {
        setCheckedBankAccounts(prevOrgs => {
            if (prevOrgs.includes(orgId)) {
                return prevOrgs.filter(id => id !== orgId);
            } else {
                return [...prevOrgs, orgId];
            }
        });
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allOrgs = bankaccs.map(bankacc => bankacc.id);
            setCheckedBankAccounts(allOrgs);
        } else {
            setCheckedBankAccounts([]);
        }
    };

    const allChecked = bankaccs.length > 0 && bankaccs.every(bankacc => checkedBankAccounts.includes(bankacc.id));

    const handleSearch = async () => {
      
        try {
            const response = await api.post('api/v1/bank/bankaccountsfilter/', {
                name: nameFilter.toLowerCase(), 
                number_of_check: nubmerFilter,
                is_active : [!archivedFilterNotArchive,archivedFilterActive],
                company : organFilter
            });
            console.log('Employees fetched:', response.data); 
           
            setBankAccounts(response.data);
        } catch (error) {
            console.error('Error fetching bankaccounts:', error);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const requiredFields = ['name', 'account_number', 'bank', 'company', 'currency', 'account_type'];
        const errors = requiredFields.reduce((acc, key) => {
            if (!formData[key]) {
                if (key === 'bank' || key === 'company' || key === 'currency') {
                    acc[key] = 'Выберите значение';
                } else {
                    acc[key] = 'Заполните поле';
                }
            }
            return acc;
        }, {});
      
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        const getIdOrValue = (obj) => obj?.id || obj || '';
        setLoading(true);
        const preparedFormData = {
            ...formData,
            company: getIdOrValue(formData.company),
            bank: getIdOrValue(formData.bank),
            currency: getIdOrValue(formData.currency),
            owner_organization: getIdOrValue(formData.owner_organization),
            owner_counterparty: getIdOrValue(formData.owner_counterparty),
        };
        try {
            if (isEditing) {
                await api.patch(`/api/v1/bank/bankaccounts/${formData.id}/update/`, preparedFormData);
            } else {
                await api.post('api/v1/bank/createbankaccount/', formData);
            }
            
            handleCloseModal();
            fetchBankAccounts();
        } catch (error) {
            alert('Заполните все поля');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: '' }));
        }
    };
    const resetForm = () => {
        setFormData(initialFormData);
        setIsEditing(false);
    };

    const handleCloseModal = () => {
        resetForm();
        setShowModal(false);
        setErrors({});
    };

    const handleRowClick = (bankacc) => {

        if (!permissions.includes('bank.change_bankaccount') && !user.is_superuser) {
            return;
        }
        setIsEditing(true);
        setFormData({
            id: bankacc.id,
            name: bankacc.name,
            account_number: bankacc.account_number || '',
            code: bankacc.code || '',
            bank: bankacc.bank,
            company: bankacc.company,
            account_type: bankacc.account_type || '',
            currency: bankacc.currency,
            owner_organization: bankacc.owner_organization || '',
            owner_counterparty: bankacc.owner_counterparty || ''
        });
        setShowModal(true);
    };
    const title = isEditing ? "Редактирование банковского счета" : "Создание банковского счета";
    return (
        <div className="container-fluid" translate="no">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="management">
                        <div className="row">
                            <div className="col-12">
                                <PageHeading>Банковские счета</PageHeading>
                                
                                {/* Секция фильтров */}
                                <div className="mb-3 p-3 border rounded">
                                    <h5>Фильтры:</h5>
                                    <div className="row g-3">
                                        <div className="col-md-4 col-lg-3">
                                            <label className="form-label">Название счета</label>
                                            <input
                                                type="text"
                                                placeholder="Введите название счета"
                                                value={nameFilter}
                                                onChange={e => setNameFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <label className="form-label">Номер счета</label>
                                            <input
                                                type="text"
                                                placeholder="Введите номер счета"
                                                value={nubmerFilter}
                                                onChange={e => setnumberFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <label className="form-label">Организация</label>
                                            <APIMultipleSearchableSelect
                                                endpoint="/api/v1/organization/search/"
                                                value={organFilter}
                                                onChange={(value) => setSelectedOrganization(value)}
                                                placeholder="Выберите организацию"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <label className="form-label">Архивный</label>
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckNotActive"
                                                        checked={archivedFilterNotArchive}
                                                        onChange={e => setArchivedFilterNotActive(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="archivedCheckActive"
                                                        checked={archivedFilterActive}
                                                        onChange={e => setArchivedFilterActive(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="archivedCheckActive">
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button onClick={handleSearch} className="btn btn-primary me-2">
                                                Поиск
                                            </button>
                                            <button onClick={handleReset} className="btn btn-outline-secondary">
                                                Сбросить
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* Кнопки действий */}
                                <div className="mb-3">
                                    <ProtectedContent perms={'bank.change_bankaccount'}>
                                        <button 
                                            onClick={handleArchiveBankAccounts} 
                                            className="btn btn-secondary me-2"
                                            disabled={checkedBankAccounts.length === 0}
                                        >
                                            Архивировать выбранные
                                        </button>
                                        <button 
                                            onClick={handleUnarchiveBankAccounts} 
                                            className="btn btn-success me-2"
                                            disabled={checkedBankAccounts.length === 0}
                                        >
                                            Разархивировать выбранные
                                        </button>
                                    </ProtectedContent>
                                    <ProtectedContent perms={'bank.view_bankaccount'}>
                                    <button className="btn btn-primary me-2" onClick={() => setShowModal(true)}>
                                        Добавить
                                    </button>
                                    </ProtectedContent>
                                    <button onClick={fetchBankAccounts} className="btn btn-primary">Обновить</button>
                                </div>

                                {/* Таблица */}
                                <div ref={containerRef} className="table-responsive" style={{ maxHeight: '55vh', overflowX: 'auto' }}>
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input 
                                                        type="checkbox" 
                                                        checked={allChecked}
                                                        onChange={handleSelectAllChange}
                                                    />
                                                </th>
                                                <th>Название</th>
                                                <th>Номер</th>
                                                <th>Организация</th>
                                                <th>Код</th>
                                                <th>Вид счета</th>
                                                <th>Банк</th>
                                                <th>Валюта</th>
                                                <th>Владелец (Орг.)</th>
                                                <th>Владелец (Контр.)</th>
                                                <th>Архивный</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bankaccs.map((bankacc) => (
                                                <tr key={bankacc.id} onDoubleClick={() => handleRowClick(bankacc)}>
                                                    <td>
                                                        <input 
                                                            type="checkbox"
                                                            checked={checkedBankAccounts.includes(bankacc.id)}
                                                            onChange={() => handleCheckboxChange(bankacc.id)}
                                                        />
                                                    </td>
                                                    <td>{bankacc.name}</td>
                                                    <td>{bankacc.account_number}</td>
                                                    <td>{bankacc.company?.name}</td>
                                                    <td>{bankacc.code}</td>
                                                    <td>{bankacc.account_type}</td>
                                                    <td>{bankacc.bank?.name}</td>
                                                    <td>{bankacc.currency?.name}</td>
                                                    <td style={{ 
                                                    width: '200px', 
                                                    maxWidth: '200px', 
                                                    whiteSpace: 'normal', 
                                                    wordWrap: 'break-word' 
                                                    }}>{bankacc.owner_organization === null 
                                                        ? '' 
                                                        : bankacc.owner_organization?.name || ''}</td>
                                                    <td style={{ 
                                                    width: '200px', 
                                                    maxWidth: '200px', 
                                                    whiteSpace: 'normal', 
                                                    wordWrap: 'break-word' 
                                                    }}>{bankacc.owner_counterparty === null 
                                                        ? '' 
                                                        : bankacc.owner_counterparty?.name || ''}</td>
                                                    <td>{bankacc.is_active ? 'Нет' : 'Да'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                {loading && 
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="bankaccount-form" onSubmit={handleSubmit}>
                    <div className="form_input">
                        <div className="row g-1">
                            <div className="col-md-12">
                                <label className="form-label">Наименование банковского счета*</label>
                                <input
                                    className={`form-control ${errors.name ? "is-invalid" : ""}`}
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    placeholder="Введите наименование"
                                    onChange={handleChange}
                                />
                                {errors.name && <span className="invalid-feedback">{errors.name}</span>}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Номер счета*</label>
                                <input
                                    className={`form-control ${errors.account_number ? "is-invalid" : ""}`}
                                    type="text"
                                    name="account_number"
                                    value={formData.account_number}
                                    onChange={handleChange}
                                    placeholder="Введите номер счета"
                                />
                                {errors.account_number && <span className="invalid-feedback">{errors.account_number}</span>}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Банк*</label>
                                <SearchableSelect
                                    endpoint="/api/v1/bank/"
                                    value={formData.bank || ""}
                                    onChange={(value) => handleChange({ target: { name: "bank", value } })}
                                    placeholder="Выберите банк"
                                    className={errors.bank ? "is-invalid" : ""}
                                    isClearable
                                    isMulti={false}
                                />
                                {errors.bank && (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {errors.bank}
                                    </div>
                                )}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Валюта*</label>
                                <SearchableSelect
                                    endpoint="/api/v1/bank/currencies/"
                                    value={formData.currency || ""}
                                    onChange={(value) => handleChange({ target: { name: "currency", value } })}
                                    placeholder="Выберите валюту"
                                    className={errors.currency ? "is-invalid" : ""}
                                    isClearable
                                    isMulti={false}
                                />
                                {errors.currency && (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {errors.currency}
                                    </div>
                                )}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Вид счета*</label>
                                <input
                                    className={`form-control ${errors.account_type ? "is-invalid" : ""}`}
                                    type="text"
                                    name="account_type"
                                    value={formData.account_type}
                                    onChange={handleChange}
                                    placeholder="Введите номер счета"
                                />
                                {errors.account_type && (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {errors.account_type}
                                    </div>
                                )}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Организация*</label>
                                <SearchableSelect
                                    endpoint="/api/v1/organization/"
                                    value={formData.company || ""}
                                    onChange={(value) => handleChange({ target: { name: "company", value } })}
                                    placeholder="Выберите организацию"
                                    className={errors.company ? "is-invalid" : ""}
                                    isClearable
                                    isMulti={false}
                                />
                                {errors.company && (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {errors.company}
                                    </div>
                                )}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Владелец (Организация)</label>
                                <SearchableSelect
                                    endpoint="/api/v1/organization/"
                                    value={formData.owner_organization || ""}
                                    onChange={(value) => handleChange({ target: { name: "owner_organization", value } })}
                                    placeholder="Выберите организацию"
                                    isClearable
                                    isMulti={false}
                                />
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Владелец (Контрагент)</label>
                                <SearchableSelect
                                    endpoint="api/v1/bills/counterparty/search/"
                                    value={formData.owner_counterparty || ""}
                                    onChange={(value) => handleChange({ target: { name: "owner_counterparty", value } })}
                                    placeholder="Выберите контрагента"
                                    className={errors.owner_counterparty ? "is-invalid" : ""}
                                    isClearable
                                    isMulti={false}
                                />
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Код</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="code"
                                    value={formData.code}
                                    onChange={handleChange}
                                    placeholder="Введите код"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                    Закрыть
                </button>
                <button className="btn btn-primary" onClick={handleSubmit}>
                    {isEditing ? "Сохранить" : "Создать"}
                </button>
            </Modal.Footer>
        </Modal>
        </div>
    );
};

export default BankAccounts;
