import "./PageHeading.css"

const PageHeading = ({ children }) => {
    return (
        <>
            <div className={"PageHeading"}>{children}</div>
        </>
    )
}

export default PageHeading