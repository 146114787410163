import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { useState, useEffect, useMemo } from "react";
import api from "../api";
import "../styles/finmodel.css";
import ProtectedContent from './ProtectedContent';
import EmployeeMultipleSearchableSelect from './finmodelSelect/MultiSelectFinmodel'; 
import EmployeeSearchableSelect from './finmodelSelect/SearchableSelectUsers';
import APISearchableSelect from "./payments/SearchableSelect";

const GroupTable = () => {
    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [newGroupName, setNewGroupName] = useState("");
    const [parentGroupId, setParentGroupId] = useState("");
    const [selectedManager, setSelectedManager] = useState("");
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [isMainGroup, setIsMainGroup] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [groupToDelete, setGroupToDelete] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [typeOfBlock, setTypeOfBlock] = useState([]);
    const [connection, setConnection] = useState(null);
    const [project, setProject] = useState(null);


    const fetchUsers = async () => {
        try {
            const response = await api.get('/api/v1/user/search/');
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users', error);
        }
    };


    const fetchGroups = async () => {
        try {
            const response = await api.get('/api/v1/finmodel/block/');
            const groupsData = response.data;
    
            const mainGroup = groupsData.find(group => group.parent === null);
    
            if (!mainGroup) {
                const newGroup = {
                    "name": "Главная группа",
                    "parent": null,
                    "is_info_block": true
                };
                const createResponse = await api.post('/api/v1/finmodel/block/create/', newGroup, {
                    headers: {
                      'Content-Type': 'application/json'
                    }
                });
                setGroups([createResponse.data]);
                return [createResponse.data];
            } else {
                setGroups(groupsData);
                return groupsData;
            }
        } catch (error) {
            console.error('Error fetching groups', error);
            return [];
        }
    };

    useEffect(() => {
        fetchGroups();
        fetchUsers();
    }, []);

    const buildTree = (nodes, parent = null) => {
        return nodes
            .filter(node => node.parent === parent)
            .map(node => ({ ...node, children: buildTree(nodes, node.id) }));
    };

    const tree = useMemo(() => buildTree(groups), [groups]);

    const handleAddGroup = async () => {
        try {
            // Подготовка сотрудников для отправки
            const employeesToAdd = selectedEmployees.map(emp => 
                typeof emp === 'object' ? emp.id : emp
            );
    
            // Подготовка ID руководителя
            const supervisorId = typeof selectedManager === 'object' 
                ? selectedManager.id 
                : selectedManager;
    
            const payload = {
                name: newGroupName,
                parent: parentGroupId || null,
                supervisor: supervisorId,
                employees: employeesToAdd,
                project: typeOfBlock === "project" ? project : null,
                connection: typeOfBlock === "organization" ? connection : null,
                is_info_block: typeOfBlock === "informational"
            };
    
            // Создание группы вместе с сотрудниками и руководителем
            const response = await api.post('/api/v1/finmodel/block/create/', payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            // Обновляем список групп
            await fetchGroups();
    
            resetForm();
            setShowModal(false);
        } catch (error) {
            console.error('Error creating group', error);
        }
    };
    
    const handleEditGroup = async () => {
        try {
            // Подготовка сотрудников для отправки
            const employeesToUpdate = selectedEmployees.map(emp => 
                typeof emp === 'object' ? emp.id : emp
            );
            const getIdOrValue = (obj) => obj?.id || obj || '';
            // Подготовка ID руководителя
            const supervisorId = typeof selectedManager === 'object' 
                ? selectedManager.id 
                : selectedManager;
    
            const payload = {
                name: newGroupName,
                parent: parentGroupId || null,
                supervisor: supervisorId || null,
                employees: employeesToUpdate,
                project: typeOfBlock === "project" ? getIdOrValue(project) : null,
                connection: typeOfBlock === "organization" ? getIdOrValue(connection) : null,
                is_info_block: typeOfBlock === "informational"
            };
    
            // Обновление группы вместе с сотрудниками и руководителем
            await api.put(`/api/v1/finmodel/block/update/${selectedGroupId}/`, payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            // Обновляем список групп
            await fetchGroups();
    
            resetForm();
            setShowModal(false);
        } catch (error) {
            console.error('Error updating group', error);
        }
    };
    
    const handleOpenModal = (group, isEdit = false) => {
        setSelectedGroupId(isEdit ? group.id : null);
        setNewGroupName(isEdit ? group.name : "");
        setParentGroupId(isEdit ? (group.parent || "") : group.id);
        setConnection(isEdit ? group.connection : null);
        setProject(isEdit ? group.project : null);
        setTypeOfBlock(isEdit ? (group.connection ? "organization" : group.project ? "project" : "informational") : "");
        // Обработка руководителя при открытии модального окна
        if (isEdit) {
            const supervisorData = users.find(user => user.id === group.supervisor);
            setSelectedManager(supervisorData || "");
        } else {
            setSelectedManager("");
        }
    
        // Обработка сотрудников при открытии модального окна
        if (isEdit && group.employees) {
            const employeesData = group.employees.map(empId => {
                const employee = users.find(user => user.id === empId);
                return employee || empId;
            });
            setSelectedEmployees(employeesData);
        } else {
            setSelectedEmployees([]);
        }
    
        setIsMainGroup(group.parent === null);
        setShowModal(true);
    };

    const handleEmployeeChange = (e) => {
        const value = parseInt(e.target.value, 10);
        setSelectedEmployees(prev =>
            e.target.checked ? [...prev, value] : prev.filter(id => id !== value)
        );
    };

    const resetForm = () => {
        setNewGroupName("");
        setParentGroupId("");
        setSelectedManager(null);
        setSelectedEmployees([]);
        setSelectedGroupId(null);
        setIsMainGroup(false);
        setConnection(null);
        setProject(null);
    };

    const handleDeleteGroup = async () => {
        if (groupToDelete) {
            setIsDeleting(true);
            try {
                await api.delete(`/api/v1/finmodel/block/${groupToDelete.id}/delete/`);
                
                await fetchGroups();
                
                setShowDeleteModal(false);
                setGroupToDelete(null);
            } catch (error) {
                console.error('Error deleting group', error);
            } finally {
                setIsDeleting(false);
            }
        }
    };

    const mainGroup = groups.find(group => group.parent === null);
    const filteredUsers = users.filter(user => !selectedEmployees.includes(user.id));
    const selectedEmployeeIds = selectedEmployees.map(emp => 
        typeof emp === 'object' ? emp.id : emp
    );
    return (
        <div className="container-fluid">
            <h2 className="text-center">{mainGroup ? mainGroup.name : "Главная группа"}</h2>
            <div className="tree-container">
                <div className="tree-wrapper">
                    {tree.map(rootNode => (
                        <TreeNode 
                            key={rootNode.id} 
                            node={rootNode} 
                            users={users} 
                            setShowModal={setShowModal} 
                            handleOpenModal={handleOpenModal} 
                            setShowDeleteModal={setShowDeleteModal} 
                            setGroupToDelete={setGroupToDelete}
                            isMainGroup={true}
                        />
                ))}
                </div>
            </div>

            <div className={`modal ${showModal ? 'd-block' : 'd-none'}`} tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{selectedGroupId ? "Редактировать блок" : "Добавить блок"}</h5>
                            <button type="button" className="btn-close" onClick={() => { resetForm(); setShowModal(false); }}></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="mb-3">
                                    <label htmlFor="groupName" className="form-label">Наименование *</label>
                                    <input type="text" className="form-control" placeholder="Введите наименование" id="groupName" value={newGroupName} onChange={(e) => setNewGroupName(e.target.value)} />
                                </div>
                                {!isMainGroup && (
                                        <div className="mb-3">
                                            <label htmlFor="parentGroup" className="form-label">Вышестоящее подразделение *</label>
                                            <select className="form-select" id="parentGroup" value={parentGroupId} onChange={(e) => setParentGroupId(e.target.value)}>
                                                <option value="">Выберите подразделение</option>
                                                {groups
                                                    .filter(group => group.id !== selectedGroupId)
                                                    .map(group => (
                                                        <option key={group.id} value={group.id}>{group.name}</option>
                                                    ))}
                                            </select>
                                        </div>
                                    )}
                                <div className="d-flex justify-content-evenly mb-3">
                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="typeOfBlock"
                                            id="btnradio1"
                                            value="organization"
                                            checked={typeOfBlock === "organization"}
                                            onChange={(e) => setTypeOfBlock(e.target.value)}
                                        />
                                        <label class="btn btn-light" for="btnradio1">Организация</label>

                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="typeOfBlock"
                                            id="btnradio2"
                                            value="project"
                                            checked={typeOfBlock === "project"}
                                            onChange={(e) => setTypeOfBlock(e.target.value)}
                                        />                                        
                                        <label class="btn btn-light" for="btnradio2">Проект</label>

                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="typeOfBlock"
                                            id="btnradio3"
                                            value="informational"
                                            checked={typeOfBlock === "informational"}
                                            onChange={(e) => setTypeOfBlock(e.target.value)}
                                        />
                                        <label class="btn btn-light" for="btnradio3">Информативный блок</label>
                                </div>
                                {typeOfBlock === "project" && (
                                    <div className="mb-3">
                                        <label htmlFor="project" className="form-label">Проект *</label>
                                        <APISearchableSelect
                                            endpoint="/api/v1/organization/projects/search/"
                                            value={project} 
                                            onChange={(value) => setProject(value)}
                                            placeholder="Выберите проект"
                                        />
                                    </div>
                                )}
                                {typeOfBlock === "organization" && (
                                    <div className="mb-3">
                                        <label htmlFor="connection" className="form-label">Подключение *</label>
                                        <APISearchableSelect
                                            endpoint="/api/v1/connection/search/"
                                            value={connection} 
                                            onChange={(value) => setConnection(value)}
                                            placeholder="Выберите подлючение"   
                                        />
                                    </div>
                                )}
                                <div className="mb-3">
                                    <label htmlFor="manager" className="form-label">Руководитель</label>
                                    <EmployeeSearchableSelect
                                        value={selectedManager}
                                        onChange={(employee) => setSelectedManager(employee)}
                                        placeholder="Выберите руководителя"
                                        excludeIds={selectedEmployeeIds}
                                        key={`manager-select-${showModal}`} 
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Сотрудники</label>
                                    <EmployeeMultipleSearchableSelect 
                                        value={selectedEmployees}
                                        selectedManager = {selectedManager}
                                        onChange={(selectedEmployees) => setSelectedEmployees(selectedEmployees)}
                                        placeholder="Выберите сотрудников"
                                        key={`employees-select-${showModal}`} 
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => { resetForm(); setShowModal(false); }}>Закрыть</button>
                            <button type="button" className="btn btn-primary" onClick={selectedGroupId ? handleEditGroup : handleAddGroup}>{selectedGroupId ? "Сохранить" : "Добавить"}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal modal-finmodel ${showDeleteModal ? 'd-block' : 'd-none'}`} tabIndex="-1">
                <div className="modal-dialog modal-dialog-finmodel">
                <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Подтверждение удаления</h5>
                <button type="button" className="btn-close" onClick={() => setShowDeleteModal(false)}></button>
            </div>
            <div className="modal-body">
                <p>Вы уверены, что хотите удалить группу "{groupToDelete?.name}"?</p>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowDeleteModal(false)} disabled={isDeleting}>Отмена</button>
                <button type="button" className="btn btn-danger" onClick={handleDeleteGroup} disabled={isDeleting}>
                {isDeleting ? (
                <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Удаление...
                </>
                ) : 'Удалить'}
                </button>
                </div>
                </div>
            </div>
        </div>
        </div>
    );
};

// Компонент TreeNode для отображения узла дерева
const TreeNode = ({ node, users, setShowModal, handleOpenModal, setShowDeleteModal, setGroupToDelete, isMainGroup }) => {
    const user = users.find(u => u.id === (node.supervisor || node.author));
    
    return (
        <div className={`tree-node ${isMainGroup ? 'main-group' : ''}`}>
            <div className="card mb-3 shadow-sm block-tree">
                <div className="card-header block-tree-header">
                    <span className={`badge rounded-pill ${node.connection ? 'bg-success' : node.project ? 'bg-primary' : 'bg-secondary'} text-white`}>
                        {node.project ? "Проект" : node.connection ? "Организация" : "Информативный блок"}
                    </span>
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="mb-0 me-3">{node.name}</h6>
                        <div className="d-flex">
                            <ProtectedContent perms={'organization.change_finmodel'}>
                                <button className="btn btn-light me-1" onClick={() => handleOpenModal(node, true)}>
                                    <i className="bi bi-pencil"></i>
                                </button>
                            </ProtectedContent>
                            <ProtectedContent perms={'organization.add_finmodel'}>
                                <button className="btn btn-light me-1" onClick={() => handleOpenModal(node)}>
                                    <i className="bi bi-plus-circle"></i>
                                </button>
                            </ProtectedContent>
                            {node.parent !== null && (
                                <button className="btn btn-light" onClick={() => {
                                    setGroupToDelete(node);
                                    setShowDeleteModal(true);
                                }}>
                                    <i className="bi bi-trash"></i>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="card-body block-tree-body">
                    <div className="mb-1">
                        <div>
                            {user ? (
                                <p><i className="bi bi-person-circle me-2"></i>{user.first_name + ' ' + user.last_name}</p>
                            ) : (
                                <p>Нет руководителя</p>
                            )}
                        </div>
                    </div>
                    <div>
                        <p>Сотрудники</p>
                        {node.employees && node.employees.length > 0 ? (
                            node.employees.map(userObj => {
                                const employeeUser = users.find(u => u.id === userObj);
                                return employeeUser ? (
                                    <p key={employeeUser.id}>
                                        <i className="bi bi-person-circle me-2"></i>
                                        {employeeUser.first_name + ' ' + employeeUser.last_name}
                                    </p>
                                ) : null;
                            })
                        ) : (
                            <p>Нет сотрудников</p>
                        )}
                    </div>
                </div>
            </div>
            {node.children && node.children.length > 0 && (
                <div className="tree-children">
                    {node.children.map(child => (
                        <TreeNode 
                            key={child.id} 
                            node={child} 
                            users={users} 
                            setShowModal={setShowModal} 
                            handleOpenModal={handleOpenModal} 
                            setShowDeleteModal={setShowDeleteModal} 
                            setGroupToDelete={setGroupToDelete}
                            isMainGroup={false}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default GroupTable;
