import "../styles/sidebar.css"

import {Link, useNavigate} from 'react-router-dom';
import ProtectedContent from './ProtectedContent';

const sidebarItems = [
    {
        title: "Рабочий стол",
        icon: "bi-house",
        href: "/",
        protected: false
    },
    {
        title: "PRO-отчеты",
        icon: "bi-graph-down",
        href: "/analytics",
        protected: false
    },
    {
        title: "Платежи",
        icon: "bi-currency-dollar",
        href: "/payment-registry",
        protected: true,
        perms: ["payment.view_payment"]
    },
    {
        title: "Заработные платы",
        icon: "bi-cash-stack",
        href: "/salaries",
        protected: true,
        perms: ['salary.view_salarymatrix']
    },
    {
        title: "Движение денег",
        icon: "bi-cash-stack",
        href: "/budgeting",
        protected: true,
        perms: ['budget.view_budget']
    },
    {
        title: "Справочники",
        icon: "bi-grid",
        href: "//",
        protected: false,
        items: [
            {
                title: "Банковские счета",
                protected: true,
                href: "/bankacc",
                perms: ['bank.view_bankaccount']
            },
            {
                title: "Статьи",
                protected: true,
                href: "/captions",
                perms: ['payment.view_caption']
            },
            {
                title: "Контрагенты",
                protected: true,
                href: "/counterparty",
                perms: ['payment.view_counterparty']
            },
            {
                title: "Проекты",
                protected: true,
                href: "/projects",
                perms: ['organization.view_project']
            },
            {
                title: "Физические лица",
                protected: true,
                href: "/employees",
                perms: ['payment.view_individual']
            },
            {
                title: "Организации",
                protected: true,
                href: "/employees",
                perms: ['organization.view_company']
            },
            {
                title: "Бюджеты",
                protected: true,
                href: "/budgets",
                perms: ['budget.view_budget']
            },
            {
                title: "Типы документа",
                protected: true,
                href: "/documentTypes",
                perms: ['payment.view_documenttypes']
            },
            {
                title: "Виды операций",
                protected: true,
                href: "/operationTypes",
                perms: ['payment.view_operationtypes']
            },
        ]
    }
]

const SideBar = ({
    current
                 }) => {
    const nav = useNavigate()

    return (
        <>
            <div className="SideBar">
                <div className="SideBar__wrapper">
                    {sidebarItems.map((item, i) => (
                        <>
                        <div className={`SideBar__Item ${current == item.href ? "SideBar__current" : ""}`} key={i} onClick={() => {
                            if (!item.items) {
                                nav(item.href)
                            } else {
                                const el = document.getElementsByClassName("SideBar__SubItems" + i)
                                console.log(el)
                                el[0].style.display = "block"
                            }
                        }}>
                            {item.protected ? (
                                <ProtectedContent perms={item.perms}>
                                    <div className="SideBar__icon">
                                    <i className={`fs-5 ${item.icon}`}>

                                    </i>
                                    </div>

                                    <div className="SideBar__title">
                                        {item.title}
                                    </div>
                                </ProtectedContent>
                            ) : (
                                <>
                                    <div className="SideBar__icon">
                                    <i className={`fs-5 ${item.icon}`}>

                                    </i>
                                    </div>

                                    <div className="SideBar__title">
                                        {item.title}
                                    </div>
                                </>
                            )}
                        </div>

                            <div className={"SideBar__SubItems" + i} style={{
                                display: "none"
                            }}>
                                {item?.items?.map((item, i) => (
                                    <>
                                        <div className="SideBar__SubItem" onClick={() => {
                                            nav(item.href)
                                        }}>
                                            {item.title}
                                        </div>
                                    </>
                                ))}
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </>
    );
}

export default SideBar;