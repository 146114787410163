import "./Input.css"

const Input = ({
                    children,
                    ...options
                }) => {
    return (
        <>
            <input className={`Input`} {...options}>
                {children}
            </input>
        </>
    )
}

export default Input;