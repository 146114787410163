import axios from "axios"

const api = axios.create({
    baseURL: "https://expansion-hub.com"
    
})

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("access")
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
            config.headers["referrerPolicy"] = "unsafe-url"
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default api