import {useState, useEffect} from "react";
import api from "../api";
import SideBar from "./SideBar";
import Header from "./Header";
import {LineChart, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip} from "recharts";
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import ReactLoading from 'react-loading';
import PageHeading from "./ui/PageHeading/PageHeading";
import Card from "./ui/Card/Card";

import "../styles/home.css"
import Heading from "./ui/Heading/Heading";

function Home() {
    const [bankBalances, setBankBalances] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedAccount, setSelectedAccount] = useState([]);
    const [selectedName, setSelectedName] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState('week');
    const [chartLoading, setChartLoading] = useState(false);

    const fetchBankBalances = async () => {
        setLoading(true);
        try {
            const response = await api.get('api/v1/bank/bankaccount_balances/');
            setBankBalances(response.data);
            if (response.data.length > 0) {
                setSelectedName(response.data[0]?.bankaccount?.name)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchBankBalances();
    }, [])

    const fetchSelectedAccount = async () => {
        try {
            setChartLoading(true);
            const response = await api.get(`api/v1/bank/bankaccount_balances/retrieve/?account_name=${selectedName}&period=${selectedPeriod}`);
            setSelectedAccount(response.data);
        } catch (error) {
            console.log(error)
        } finally {
            setChartLoading(false);
        }
    }

    const handleRowClick = (accountName) => {
        if (accountName !== selectedName) {
            setSelectedName(accountName);
        }
    };

    const handlePeriodClick = (period) => {
        setSelectedPeriod(period);
    }

    useEffect(() => {
        if (selectedName) {
            fetchSelectedAccount();
        }
    }, [selectedName, selectedPeriod]);

    return (
        <div className="container-fluid">
            <Header/>
            <Card className="row flex-nowrap">
                <SideBar current="/"/>
                <div className="col py-3">
                    <PageHeading>Рабочий стол</PageHeading>
                    {!loading ? (<div className="Home__row">
                        <Card>
                            <Heading level={5} style={{
                                marginBottom: "10px",
                            }}>Банковские остатки</Heading>
                            <table className="table table-borderless table-sm">
                                <thead>
                                <tr>
                                    <th className="text-start" style={{width: '35%'}}>Остатки на счетах</th>
                                    <th className="text-start" style={{width: '35%'}}>Сумма</th>
                                    <th className="text-start" style={{width: '30%'}}>Дата обновления</th>
                                </tr>
                                </thead>
                                <tbody>
                                {bankBalances.map((balance) => (
                                    <tr key={balance.id}
                                        className={balance?.bankaccount?.name === selectedName ? 'table-primary' : ''}
                                        onClick={() => {
                                            handleRowClick(balance?.bankaccount?.name)
                                        }}>
                                        <td className="text-start">{balance?.bankaccount?.name}</td>
                                        <td className="text-start">{balance?.sum} {balance?.currency?.letter_code}</td>
                                        <td className="text-start">{balance.created}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </Card>
                        <Card>
                            <div className="btn-toolbar align-items-center" role="toolbar">
                                <Heading level={5}>Динамика остатков на счетах</Heading>
                                <div className="btn-group ms-auto" role="group">
                                    {['week', 'month', 'quarter', 'half_year', 'year'].map(period => (
                                        <button
                                            key={period}
                                            type="button"
                                            className={selectedPeriod === period ? "btn btn-outline-secondary btn-sm active" : "btn btn-outline-secondary btn-sm"}
                                            onClick={() => handlePeriodClick(period)}
                                        >
                                            {period === 'week' ? 'НЕДЕЛЯ' : period === 'month' ? 'МЕСЯЦ' : period === 'quarter' ? 'КВАРТАЛ' : period === 'half_year' ? 'ПОЛГОДА' : 'ГОД'}
                                        </button>
                                    ))}
                                </div>
                            </div>

                            {!chartLoading ?<>
                                    <Line style={{
                                        marginTop: "20px",
                                    }} options={{
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                display: false,
                                            }
                                        }
                                    }} data={{
                                        labels: selectedAccount.map((account) => account.created),
                                        datasets: [
                                            {
                                                label: " ",
                                                data: selectedAccount.map((account) => account.sum),
                                                borderColor: '#007bff',
                                                backgroundColor: '#007bff',
                                            }
                                        ]
                                    }} />
                            </>:
                                (<div className="position-absolute top-50 start-50 translate-middle">
                                    <ReactLoading type="spin" color="#0000FF" height={30} width={30}/>
                                </div>)}
                            <p className="mt-3 text-center">Ежедневное время обновления банковских счетов - 03:00</p>
                        </Card>
                    </div>) : (<div className="position-absolute top-50 start-50 translate-middle">
                        <ReactLoading type="spin" color="#0000FF" height={30} width={30}/>
                    </div>)}
                </div>
            </Card>
        </div>
    );
}

export default Home;
