import "./Card.css"

const Card = ({
    children,
    ...options
              }) => {
    return (
        <>
            <div className="Card" {...options}>
                {children}
            </div>
        </>
    )
}

export default Card