// import React, { useState, useEffect } from 'react';
import api from '../../api';
import SideBar from '../SideBar';
import Header from "../Header";
import { useState, useEffect, useRef, useCallback  } from 'react';
import ReactLoading from 'react-loading';
import APIMultipleSearchableSelect from '../payments/SearchableTableMultiSelect'; 
import { useLocation,useNavigate } from 'react-router-dom';
import ProtectedContent from '../ProtectedContent';
import APISearchableSelect from '../payments/SearchableSelect';

const Employees = () => {
    const [employees, setEmployees] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 25;
    const [checkedEmployees, setCheckedEmployees] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [iinFilter, setinnFilter] = useState('');
    const [organFilter, setSelectedOrganization] = useState('');
    const [archivedFilterNotArchive, setArchivedFilterNotActive] = useState(false);
    const [archivedFilterActive, setArchivedFilterActive] = useState(false);
    const [isFiltering, setIsFiltering] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errors, setErrors] = useState({});   
    const [formData, setFormData] = useState({
        name:'',
        lastName:'',
        firstName:'',
        middleName:'',
        iin: '',
        company: ''
    });
    const navigate = useNavigate();
    const location = useLocation();
    const searchIDofelem = location.search.replace("?","");

    
    const fetchEmployees = async (currentOffset = 0, isNewSearch = false) => {
        setLoading(true);
        try {
            let response;
            
            // Если есть активные фильтры или поиск по ID
            if (isFiltering || searchIDofelem) {
                response = await api.post('/api/v1/bills/employeefilter/', {
                    id: searchIDofelem || undefined,
                    name: nameFilter.toLowerCase() || undefined,
                    iin: iinFilter || undefined,
                    is_active: [!archivedFilterNotArchive, archivedFilterActive],
                    company: organFilter || undefined,
                    limit: limit,
                    offset: currentOffset
                });

                // Обработка результатов с пагинацией
                const newData = response.data.results || response.data;
                if (isNewSearch) {
                    setEmployees(newData);
                } else {
                    setEmployees(prev => [...prev, ...newData]);
                }
                
                // Проверка наличия следующей страницы
                setHasMore(response.data.next !== null);
            } else {
                // Обычная загрузка без фильтров
                response = await api.get(`/api/v1/bills/employee/?limit=${limit}&offset=${currentOffset}`);
                
                if (isNewSearch) {
                    setEmployees(response.data.results);
                } else {
                    setEmployees(prev => [...prev, ...response.data.results]);
                }
                
                setHasMore(response.data.next !== null);
            }
        } catch (error) {
            console.error('Error fetching Employees', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/v1/organization/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies', error)
        }
    }
    useEffect(() => {
        fetchCompanies();
    }, []); 

    useEffect(() => {
        setEmployees([]); 
        setOffset(0); 
        setHasMore(true);
        fetchEmployees();
    }, [location]);

    useEffect(() => {
        if (offset > 0) {
            fetchEmployees(offset, false);
        }
    }, [offset]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            const scrollThreshold = 50;
            
            if (scrollHeight - (scrollTop + clientHeight) <= scrollThreshold && hasMore && !loading) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        fetchCompanies();
        if (searchIDofelem) {
            setIsFiltering(true);
        }
        fetchEmployees(0, true);
    }, [searchIDofelem]);


    const handleUpdateEmployees = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(checkedEmployees.map(id => 
                api.patch(`/api/v1/bills/employee/${id}/update`, { is_active: isActive })
            ));
            setEmployees([]);
            fetchEmployees();
            setOffset(0);
            setHasMore(true);
            setCheckedEmployees([])
        } catch (error) {
            console.error(`Error ${isActive ? 'unarchiving' : 'archiving'} employees`, error);
        } finally {
            setLoading(false);
        }
    };

    const handleArchiveEmployees = () => handleUpdateEmployees(false);
    const handleUnarchiveEmployees = () => handleUpdateEmployees(true);
    
    const handleCheckboxChange = (orgId) => {
        setCheckedEmployees(prevOrgs => {
            if (prevOrgs.includes(orgId)) {
                return prevOrgs.filter(id => id !== orgId);
            } else {
                return [...prevOrgs, orgId];
            }
        });
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allOrgs = employees.map(employee => employee.id);
            setCheckedEmployees(allOrgs);
        } else {
            setCheckedEmployees([]);
        }
    };

    const allChecked = employees.length > 0 && employees.every(emp => checkedEmployees.includes(emp.id));

    const handleReset = () => {
        setIsFiltering(false);
        setNameFilter('');
        setinnFilter('');
        setSelectedOrganization('');
        setArchivedFilterNotActive(false);
        setArchivedFilterActive(false);
        setOffset(0);
        setHasMore(true);
        setEmployees([]);
        fetchEmployees(0, true);
        navigate(location.pathname);
    };
    const handleSearch = async () => {
        setIsFiltering(true);
        setOffset(0);
        setHasMore(true);
        await fetchEmployees(0, true);
    };


    const handleModalClose = () => {
        setShowModal(false);
        setFormData({
            lastName: '',
            firstName: '',
            middleName: '',
            iin: '',
            company: ''
        });
        setErrors({});
    };
    const handleModalShow = () => {
        setFormData({
            lastName: '',
            firstName: '',
            middleName: '',
            iin: '',
            company: ''
        });
        setShowModal(true);
    };

    const validateForm = async () => {
        const newErrors = {};
        
        // Validate last name
        if (!formData.lastName || !formData.lastName.trim()) {
            newErrors.name = JSON.stringify({
                lastName: 'Фамилия обязательна',
                firstName: formData.firstName ? '' : 'Имя обязательно'
            });
        }
        
        // Validate first name
        if (!formData.firstName || !formData.firstName.trim()) {
            newErrors.name = JSON.stringify({
                lastName: formData.lastName ? '' : 'Фамилия обязательна',
                firstName: 'Имя обязательно'
            });
        }
    
        // Enhanced IIN validation
        if (!formData.iin) {
            newErrors.iin = 'ИИН обязателен';
        } else if (formData.iin.length !== 12) {
            newErrors.iin = 'ИИН должен содержать ровно 12 цифр';
        } else if (!/^\d+$/.test(formData.iin)) {
            newErrors.iin = 'ИИН должен содержать только цифры';
        } else {
            // Check for duplicate IIN only if format is valid
            try {
                const response = await api.post('/api/v1/bills/employeefilter/', {
                    iin: formData.iin,
                    limit: 1
                });
    
                if (response.data.results && response.data.results.length > 0) {
                    newErrors.iin = 'Пользователь с таким ИИН уже существует';
                }
            } catch (error) {
                console.error('Error checking for duplicate IIN', error);
            }
        }
    
        // Validate company
        if (!formData.company) {
            newErrors.company = 'Организация обязательна';
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };    

    const isFormValid = () => {
        // Add IIN validation to form validation
        if (!formData.lastName?.trim() || !formData.firstName?.trim() || !formData.company || !formData.iin) {
            return false;
        }

        // Check IIN format
        if (formData.iin.length !== 12 || !/^\d+$/.test(formData.iin)) {
            return false;
        }

        if (Object.keys(errors).length > 0) {
            return false;
        }

        return true;
    };

    const handleSubmit = async () => {
        if (loading || !isFormValid()) return;
    
        const isValid = await validateForm();
        if (isValid) {
            try {
                setLoading(true);
                const submissionData = {
                    ...formData,
                    name: [formData.lastName, formData.firstName, formData.middleName]
                        .filter(Boolean)
                        .join(' ')
                };
    
                await api.post('/api/v1/bills/individual/create/', submissionData);
                handleModalClose();
                fetchEmployees(0, true);
            } catch (error) {
                if (error.response?.data?.detail?.includes('ИИН')) {
                    setErrors(prev => ({
                        ...prev,
                        iin: 'Пользователь с таким ИИН уже существует'
                    }));
                } else {
                    setErrors(prev => ({
                        ...prev,
                        apiError: 'Ошибка при создании физического лица'
                    }));
                }
            } finally {
                setLoading(false);
            }
        }
    };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        // Special handling for IIN input
        if (name === 'iin') {
            // Only allow numbers and limit to 12 digits
            const numericValue = value.replace(/\D/g, '').slice(0, 12);
            setFormData(prev => ({
                ...prev,
                [name]: numericValue
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
        
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: undefined
            }));
        }
    };

    return (
        <div className="container-fluid" translate="no">
            <Header />
            <div className="row flex-nowrap">
                <SideBar />
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Физические лица</h3>
                                </div>
                                {showModal && (
                                    <div className={`modal ${showModal ? 'd-block' : 'd-none'}`} tabIndex="-1">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header d-flex justify-content-center position-relative">
                                                    <h5 className="modal-title">Добавить физическое лицо</h5>
                                                    <button 
                                                        type="button" 
                                                        className="btn-close" 
                                                        onClick={handleModalClose}
                                                    ></button>
                                                </div>
                                                <div className="modal-body">
                                                {errors.duplicate && (
                                                <div className="alert alert-danger mx-3 mt-3" role="alert">
                                                    {errors.duplicate}
                                                </div>
                                            )}
                                            {errors.apiError && (
                                                <div className="alert alert-danger mx-3 mt-3" role="alert">
                                                    {errors.apiError}
                                                </div>
                                            )}

                                            <form className='addindividual-form'>
                                                <div className="size mb-3">
                                                    <div className="mb-3">
                                                        <label className="form-label">Фамилия *</label>
                                                        <input
                                                            type="text"
                                                            name="lastName"
                                                            className={`form-control ${errors.name && JSON.parse(errors.name).lastName ? 'is-invalid' : ''}`}
                                                            value={formData.lastName}
                                                            onChange={handleInputChange}
                                                        />
                                                        {errors.name && JSON.parse(errors.name).lastName && (
                                                            <div className="invalid-feedback">
                                                                {JSON.parse(errors.name).lastName}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Имя *</label>
                                                        <input
                                                            type="text"
                                                            name="firstName"
                                                            className={`form-control ${errors.name && JSON.parse(errors.name).firstName ? 'is-invalid' : ''}`}
                                                            value={formData.firstName}
                                                            onChange={handleInputChange}
                                                        />
                                                        {errors.name && JSON.parse(errors.name).firstName && (
                                                            <div className="invalid-feedback">
                                                                {JSON.parse(errors.name).firstName}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Отчество</label>
                                                        <input
                                                            type="text"
                                                            name="middleName"
                                                            className="form-control"
                                                            value={formData.middleName}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">ИИН *</label>
                                                    <input
                                                        type="text"
                                                        name="iin"
                                                        className={`form-control ${errors.iin ? 'is-invalid' : ''}`}
                                                        value={formData.iin}
                                                        onChange={handleInputChange}
                                                        maxLength="12"
                                                        required
                                                    />
                                                    {errors.iin && (
                                                        <div className="invalid-feedback">
                                                            {errors.iin}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Организация *</label>
                                                    <APISearchableSelect
                                                        name="company"
                                                        size=''
                                                        endpoint="/api/v1/organization/search/"
                                                        value={formData.company || ''}
                                                        onChange={(value) => handleInputChange({ target: { name: "company", value } })}
                                                        placeholder="Выберите организацию"
                                                        className={errors.company ? 'is-invalid' : ''}
                                                    />
                                                    {errors.company && (
                                                        <div className="invalid-feedback d-block">
                                                            {errors.company}
                                                        </div>
                                                    )}
                                                </div>
                                            </form>
                                                </div>
                                                <div className="modal-footer">
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-secondary" 
                                                        onClick={handleModalClose}
                                                    >
                                                        Закрыть
                                                    </button>
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-primary" 
                                                        onClick={handleSubmit}
                                                        disabled={loading || !isFormValid()}
                                                    >
                                                        {loading ? 'Сохранение...' : 'Сохранить'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="mb-3 p-3 border rounded">
                                    <h5>Фильтры:</h5>
                                    <div className="row">
                                        <div className="col">
                                            <label className='form-label'>ФИО сотрудника</label>
                                            <input
                                                type="text"
                                                placeholder="Введите ФИО"
                                                value={nameFilter}
                                                onChange={e => setNameFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div  className="col">
                                            <label className='form-label'>ИИН</label>
                                            <input
                                                type="text"
                                                placeholder="Введите ИИН"
                                                value={iinFilter}
                                                onChange={e => setinnFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className='form-label'>Организация</label>
                                        <APIMultipleSearchableSelect
                                            endpoint="/api/v1/organization/search/"
                                            value={organFilter}
                                            onChange={(value) => setSelectedOrganization(value)}
                                            placeholder="Выберите организацию"
                                        />
                                        </div>
                                        <div className="col">
                                            <label>Архивный сотрудник</label>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckNotActive"
                                                    checked={archivedFilterNotArchive}
                                                    onChange={e => setArchivedFilterNotActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                    Да
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckActive"
                                                    checked={archivedFilterActive}
                                                    onChange={e => setArchivedFilterActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckActive">
                                                    Нет
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col align-self-end">
                                            
                                            <button
                                                onClick={handleSearch}
                                                className="btn btn-primary mt-3"
                                            >
                                                Поиск
                                            </button>
                                            <button
                                                onClick={handleReset}
                                                className="btn btn-outline-secondary mt-3"
                                            >
                                                Сбросить
                                            </button>
                                        </div>
                                    </div>  
                                </div>
                                <div className="mb-3">
                                    <ProtectedContent perms={'payment.change_individual'}>
                                        <button 
                                            onClick={handleArchiveEmployees} 
                                            className="btn btn-secondary me-2"
                                            disabled={checkedEmployees.length === 0}
                                        >
                                            Архивировать выбранные
                                        </button>
                                        <button 
                                            onClick={handleUnarchiveEmployees} 
                                            className="btn btn-success me-2"
                                            disabled={checkedEmployees.length === 0}
                                        >
                                            Разархивировать выбранные
                                        </button>
                                        
                                    </ProtectedContent>
                                    <button
                                        onClick={handleModalShow}
                                        className='btn btn-primary me-2'
                                    >Добавить
                                    </button>
                                    <button onClick={() => setOffset(0)} className="btn btn-primary">Обновить</button>
                                </div>
                                <div ref={containerRef} className="table-responsive" style={{ height: '55vh', overflowY: 'scroll' }}>
                                    <table className="table table-striped">
                                    <thead>
                                        <tr>
                                        <th>
                                            <input 
                                                type="checkbox" 
                                                checked={allChecked}
                                                onChange={handleSelectAllChange}
                                            />
                                        </th>
                                        <th>ФИО физического лица</th>
                                        <th>ИИН</th>
                                        <th>Организация</th>
                                        <th>Архивный</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {employees.map((employee) => (
                                        <tr key={employee.name} >
                                            <td>
                                                        <input 
                                                            type="checkbox"
                                                            checked={checkedEmployees.includes(employee.id)}
                                                            onChange={() => handleCheckboxChange(employee.id)}
                                                        />
                                                    </td> 
                                            <td style={{ 
                                                    width: '300px', 
                                                    maxWidth: '300px', 
                                                    whiteSpace: 'normal', 
                                                    wordWrap: 'break-word' 
                                                    }}>{employee.name}</td>
                                            <td>{employee.iin}</td>
                                            <td style={{ 
                                                    width: '200px', 
                                                    maxWidth: '200px', 
                                                    whiteSpace: 'normal', 
                                                    wordWrap: 'break-word' 
                                                    }}>{employee.company ? companies.find(company => company.id === employee.company)?.name : ''}</td>
                                            <td>{employee.is_active ? 'Нет' : 'Да'}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                    </table>
                                </div>
                                {loading && 
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Employees;
