import { useState, useEffect } from "react";
import useFetchData from "../../functions/UprData";
import { Link, useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import ProtectedContent from '../ProtectedContent';
import { useAuth } from "../../AuthContext";

const RolesTable = () => {
    const { permissions, user } = useAuth();
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchRoles = useFetchData('api/v1/role/groups/', setRoles);

    useEffect(() => {
        setLoading(true);
        fetchRoles();
        setLoading(false);
    }, []);

    const navigate = useNavigate()
    const handleRowClick = (group) => {
        if (!permissions.includes('auth.change_group') && !user.is_superuser) {
            return;
        }
        navigate(`/edit-group/${group.id}`)
    }
    
    if (loading) {
        return (
            <div className="position-absolute top-50 start-50 translate-middle">
                <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
            </div>);
    }
    
    return (
        <>
        <ProtectedContent perms={'auth.add_group'}>
            <div className="d-flex mb-4 mt-2">
                <Link to='/create-group' className='btn btn-primary'>+ Создать группу</Link>
            </div>
        </ProtectedContent>
        <table className="table table-striped">
            <thead className="table-light">
                <tr>
                    <th>ID</th>
                    <th>Наименование</th>
                </tr>
            </thead>
            <tbody>
                {roles.map((role) => (
                    <tr key={role.id} onDoubleClick={() => handleRowClick(role)}>
                        <td>{role.id}</td>
                        <td>{role.name}</td>
                        <td className="d-none">{role.permissions ? role.permissions : ''}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </>
    )
}

export default RolesTable;