import "./Heading.css"

const Heading = ({ level, children, ...options }) => {
    return (
        <>
            <div className={`Heading Heading__${level}`} {...options}>{children}</div>
        </>
    )
}

export default Heading