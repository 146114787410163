import SideBar from '../SideBar';
import { Link } from 'react-router-dom';
import api from "../../api";
import useFetchData from "../../functions/UprData";
import Header from "../Header";
import { useState, useEffect, useRef, useCallback  } from 'react';
import ReactLoading from 'react-loading';
import APIMultipleSearchableSelect from '../payments/SearchableTableMultiSelect'; 
import { useLocation, useNavigate} from 'react-router-dom';
import SearchableSelect from '../payments/SearchableSelect'; 
import ProtectedContent from '../ProtectedContent';
import { useAuth } from "../../AuthContext";
import { Modal} from 'react-bootstrap';
import PageHeading from "../ui/PageHeading/PageHeading";

const CaptionManagement = () => {
    const { permissions, user } = useAuth();
    const [captions, setCaptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [caption_types, setCaptionTypes] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 25;
    const [checkedCaptions, setCheckedCaptions] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [captiontype, setselectedcaptiontype] = useState('');
    const [archivedFilterNotArchive, setArchivedFilterNotActive] = useState(false); //yes
    const [archivedFilterActive, setArchivedFilterActive] = useState(false);  //no

    const location = useLocation();
    const navigate = useNavigate();
    const searchIDofelem = location.search.replace("?","");
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const initialFormData = {
        name: '',
        type_of_caption: null,
        parent_caption: null,
        company: null,
        description: '',
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});

    const fetchCaptions = async () => {
        setLoading(true);
        try {
            console.log(location)
            if (searchIDofelem){
                const response = await api.post('/api/v1/bills/captionfilter/', {
                    id:searchIDofelem
                });
                setCaptions(response.data);
            }else{
                const response = await api.get(`api/v1/bills/caption/?limit=${limit}&offset=${offset}`);
            setCaptions(prevCaptions => {
                const newCaption = response.data.results.filter(newCaption => 
                    !prevCaptions.some(prevCaptions => prevCaptions.id === newCaption.id)
                );
                return [...prevCaptions, ...newCaption];
            });
            if (!response.data.next) {
                setHasMore(false);
            }
            }
        } catch (error) {
            console.error('Error fetching captions', error);
        }finally {
            setLoading(false);
        }}

    useEffect(() => {
        setCaptions([]); // Clear existing payments
        setOffset(0); // Reset offset
        setHasMore(true);
        setCompanies([]);
        setCaptionTypes([]);
        fetchCaptions();
    }, [location]);

    useEffect(() => async () => {
        if (hasMore) {
            fetchCaptions(offset);
        }
    }, [offset, hasMore]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        const fetchCaptionById = async () => {
            if (searchIDofelem) {
                try {
                    const response = await api.post('/api/v1/bills/captionfilter/', {
                        id: searchIDofelem
                    });
                    if (response.data.length > 0) {
                        setNameFilter(response.data[0].name);
                        setCaptions(response.data);
                    }
                } catch (error) {
                    console.error('Error fetching caption by ID', error);
                }
            } else {
                setNameFilter('');
                setCaptions([]);
                setOffset(0);
                setHasMore(true);
                fetchCaptions();
            }
        };

        fetchCaptionById();
    }, [searchIDofelem]);

    const handleUpdateCaptions = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(checkedCaptions.map(id => 
                api.patch(`api/v1/bills/caption/${id}/update`, { is_active: isActive })
            ));
            // Refresh the list after updating
            setCaptions([]);
            fetchCaptions();
            setOffset(0);
            setHasMore(true);
            setCheckedCaptions([]);
            // setCompanies([]);
            // setCaptionTypes([]);
        } catch (error) {
            console.error(`Error ${isActive ? 'unarchiving' : 'archiving'} Captions`, error);
        } finally {
            setLoading(false);
        }
    };

    const handleArchiveCaptions = () => handleUpdateCaptions(false);
    const handleUnarchiveCaptions = () => handleUpdateCaptions(true);
    

    const handleCheckboxChange = (captionId) => {
        setCheckedCaptions(prevCaptions => {
            if (prevCaptions.includes(captionId)) {
                return prevCaptions.filter(id => id !== captionId);
            } else {
                return [...prevCaptions, captionId];
            }
        });
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allCaptions = captions.map(caption => caption.id);
            setCheckedCaptions(allCaptions);
        } else {
            setCheckedCaptions([]);
        }
    };

    // if (loading) {
    //     return (
    //         <div className="position-absolute top-50 start-50 translate-middle">
    //             <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
    //         </div>);
    // }
    const allChecked = captions.length > 0 && captions.every(caption => checkedCaptions.includes(caption.id));


    const handleReset = () => {
        const search = location.search
        navigate(location.search.replace(search,'')); 
        window.location.reload();

    };
    const handleSearch = async () => {
        try {
            const response = await api.post('/api/v1/bills/captionfilter/', {
                name: nameFilter.toLowerCase(), 
                type : captiontype,
                is_active : [!archivedFilterNotArchive,archivedFilterActive],
            });
            console.log('Counterparties fetched:', response.data); 
            setCaptions(response.data)
        } catch (error) {
            console.error('Error fetching Counterparties:', error);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const requiredFields = ['name', 'type_of_caption'];
        const errors = requiredFields.reduce((acc, key) => {
            if (!formData[key]) {
                if (key === 'type_of_caption') {
                    acc[key] = 'Выберите значение';
                } else {
                    acc[key] = 'Заполните поле';
                }
            }
            return acc;
        }, {});
      
          if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
          }
        setLoading(true);
        const getIdOrValue = (obj) => obj?.id || obj || ''
        const preparedFormData = {
            ...formData,
            company: getIdOrValue(formData.company),
            parent_caption: getIdOrValue(formData.parent_caption),
            type_of_caption: getIdOrValue(formData.type_of_caption),
        };
        try {
            if (isEditing) {
                const response = await api.patch(`/api/v1/bills/caption/${formData.id}/update/`, preparedFormData);
                console.log('Update Response:', response.data);
            } else {
                const response = await api.post('/api/v1/bills/caption-create/', formData);
                console.log('Create Response:', response.data);
            }
            setCaptions([]);
            setOffset(0);
            handleCloseModal();
            fetchCaptions();
        } catch (error) {
            alert('Заполните все поля');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: '' }));
        }
    };
    const resetForm = () => {
        setFormData(initialFormData);
        setIsEditing(false);
    };

    const handleCloseModal = () => {
        resetForm();
        setShowModal(false);
        setErrors({});
    };

    const handleRowClick = (caption) => {
        if(!permissions.includes('payment.change_captions') && !user.is_superuser) {
            return;
        };
        setIsEditing(true);
        setFormData({
            id: caption.id,
            name: caption.name,
            type_of_caption: caption.type_of_caption,
            description: caption.description || '',
            parent_caption: caption.parent_caption,
            company: caption.company,
        });
        setShowModal(true);
    };
    const handleTypeChange = (value) => {
        setFormData(prev => ({
            ...prev,
            type_of_caption: value
        }));
        if (errors.type_of_caption) {
            setErrors(prev => ({ ...prev, type_of_caption: '' }));
        }
    };
    const handleCompanyChange = (value) => {
        setFormData(prev => ({
            ...prev,
            company: value
        }));
    };
    const handleParentCaptionChange = (value) => {
        setFormData(prev => ({
            ...prev,
            parent_caption: value
        }));
        if (errors.parent_caption) {
            setErrors(prev => ({ ...prev, parent_caption: '' }));
        }
    };
    const title = isEditing ? "Редактирование статьи" : "Создание статьи";

    return (
        <div className="container-fluid" translate="no">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <PageHeading>Статьи</PageHeading></div>
                                    <div className="mb-3 p-3 border rounded">
                                    <h5>Фильтры:</h5>
                                    <div className="row">
                                        <div className="col">
                                            <label className='form-label'>Наименование статьи</label>
                                            <input
                                                type="text"
                                                placeholder="Введиет наименование статьи"
                                                value={nameFilter}
                                                onChange={e => setNameFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className='form-label'>Тип статьи</label>
                                        <APIMultipleSearchableSelect
                                            endpoint="api/v1/bills/caption-types/search/"
                                            value={captiontype}
                                            onChange={(value) => setselectedcaptiontype(value)}
                                            placeholder="Выберите тип статьи"
                                        />
                                        </div>
                                        <div className="col">
                                            <label>Архивный</label>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckNotActive"
                                                    checked={archivedFilterNotArchive}
                                                    onChange={e => setArchivedFilterNotActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                    Да
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckActive"
                                                    checked={archivedFilterActive}
                                                    onChange={e => setArchivedFilterActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckActive">
                                                    Нет
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col align-self-end">
                                            <button
                                                onClick={handleSearch}
                                                className="btn btn-primary mt-3"
                                            >
                                                Поиск
                                            </button>
                                            <button
                                                onClick={handleReset}
                                                className="btn btn-outline-secondary mt-3"
                                            >
                                                Сбросить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div>
                                        <ProtectedContent perms={'payment.change_caption'}>
                                            <button 
                                                onClick={handleArchiveCaptions} 
                                                className="btn btn-secondary me-2"
                                                disabled={checkedCaptions.length === 0}
                                            >
                                                Архивировать выбранные
                                            </button>
                                            <button 
                                                onClick={handleUnarchiveCaptions} 
                                                className="btn btn-success me-2"
                                                disabled={checkedCaptions.length === 0}
                                            >
                                                Разархивировать выбранные
                                            </button>
                                        </ProtectedContent>
                                        <ProtectedContent perms={'payment.add_caption'}>
                                            <button className="btn btn-primary me-2" onClick={() => setShowModal(true)}>
                                                Добавить
                                            </button>
                                        </ProtectedContent>
                                        <button className="btn btn-primary" onClick={fetchCaptions}>Обновить</button>
                                    </div>
                                </div>
                                <div ref={containerRef} className="table-responsive" style={{ height: '75vh', overflowY: 'scroll' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                <input 
                                                    type="checkbox" 
                                                    checked={allChecked}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            <th>Наименование</th>
                                            <th>Описание</th>
                                            <th>Тип Статьи</th>
                                            <th>Родитель</th>
                                            <th>Организация</th>
                                            <th>Архивный</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {captions.map((caption) => (
                                            <tr key={caption.id} onDoubleClick={() => handleRowClick(caption)}>
                                                <td>
                                                    <input 
                                                        type="checkbox"
                                                        checked={checkedCaptions.includes(caption.id)}
                                                        onChange={() => handleCheckboxChange(caption.id)}
                                                    />
                                                </td>
                                                <td style={{ 
                                                    width: '300px', 
                                                    maxWidth: '300px', 
                                                    whiteSpace: 'normal', 
                                                    wordWrap: 'break-word' 
                                                    }}>{caption.name}</td>
                                                <td style={{ 
                                                    width: '300px', 
                                                    maxWidth: '300px', 
                                                    whiteSpace: 'normal', 
                                                    wordWrap: 'break-word' 
                                                    }}>{caption.description}</td>
                                                <td style={{ 
                                                    width: '200px', 
                                                    maxWidth: '200px', 
                                                    whiteSpace: 'normal', 
                                                    wordWrap: 'break-word' 
                                                    }}>{caption.type_of_caption?.name}</td>
                                                <td style={{ 
                                                    width: '200px', 
                                                    maxWidth: '200px', 
                                                    whiteSpace: 'normal', 
                                                    wordWrap: 'break-word' 
                                                    }}>{caption.parent_caption?.name}</td>
                                                <td style={{ 
                                                    width: '200px', 
                                                    maxWidth: '200px', 
                                                    whiteSpace: 'normal', 
                                                    wordWrap: 'break-word' 
                                                    }}>{caption.company?.name}</td>
                                                <td>{caption.is_active ? 'Нет' : 'Да'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </div>
                                {loading && 
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form className="bankaccount-form" onSubmit={handleSubmit}>
                <div className='form_input'>
                        <div className="row g-1">
                            <div className='col-md-12'>
                                <label className='form-label'>Наименование статьи*</label>
                                <input 
                                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                    type="text" 
                                    name="name" 
                                    value={formData.name}
                                    placeholder='Наименование' 
                                    onChange={handleChange}
                                />
                                {errors.name && <span className="invalid-feedback">{errors.name}</span>}
                           </div>
                            <div className='col-md-12'>
                                <label className='form-label'>Тип статьи*</label>
                                <SearchableSelect
                                    endpoint="api/v1/bills/caption-types/search/"
                                    value={formData.type_of_caption || ''}
                                    onChange={handleTypeChange}
                                    placeholder="Выберите тип статьи"
                                    className={`form-control ${errors.type_of_caption ? 'is-invalid' : ''}`}
                                    isClearable={true}
                                   isMulti={false}
                                />
                                 {errors.type_of_caption && (
                                    <div className="invalid-feedback" style={{display: 'block'}}>
                                        {errors.type_of_caption}
                                    </div>
                                )}
                            </div>
                            <div className='col-md-12'>
                                <label className='form-label'>Родительская статья</label>
                                <SearchableSelect
                                    endpoint="api/v1/bills/caption/search/"
                                    value={formData.parent_caption || ''}
                                    onChange={handleParentCaptionChange}
                                    placeholder="Выберите родительскую статью"
                                    className={`form-control ${errors.parent_caption ? 'is-invalid' : ''}`}
                                    isClearable={true}
                                    isMulti={false}
                                />
                            </div>
                            <div className='col-md-12'>
                                <label className='form-label'>Организация</label>
                                <SearchableSelect
                                    endpoint="api/v1/organization/search/"
                                    value={formData.company || ''}
                                    onChange={handleCompanyChange}
                                    placeholder="Выберите компанию"
                                    isClearable={true}
                                    isMulti={false}
                                />
                            </div>
                            <div className="col-md-12">
                                <label className='form-label'>Описание</label>
                                <input 
                                    className="form-control" 
                                    type="text" 
                                    name="description"
                                    value={formData.description} 
                                    onChange={handleChange} 
                                    placeholder='Введите описание'
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                    Закрыть
                </button>
                <button className="btn btn-primary" onClick={handleSubmit}>
                    {isEditing ? "Сохранить" : "Создать"}
                </button>
            </Modal.Footer>
        </Modal>
        </div>
    );
}

export default CaptionManagement;