import React, { useState, useEffect, useRef, useCallback } from 'react';
import { debounce } from 'lodash';
import api from '../../api';
import SideBar from '../SideBar';
import Header from "../Header";
import ReactLoading from 'react-loading';
import APIMultipleSearchableSelect from '../payments/SearchableTableMultiSelect'; 
import { useLocation, useNavigate } from 'react-router-dom';
import ProtectedContent from '../ProtectedContent';

const Nomenclature = () => {
    const [nomenclature, setNomenclature] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 25;

    const [filters, setFilters] = useState({
        nameFilter: '',
        organFilter: '',
        service: false,
        product: false,
        archivedFilterNotArchive: false,
        archivedFilterActive: false
    });

    const [checkedNomenclature, setCheckedNomenclature] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const searchIDofelem = location.search.replace("?","");

    const handleFilterChange = (key, value) => {
        setFilters(prev => ({
            ...prev,
            [key]: value
        }));
    };

    const fetchNomenclature = async (currentOffset = 0, isNewSearch = false) => {
        setLoading(true);
        try {
            const isFiltering = Object.values(filters).some(val => val !== false && val !== '') || searchIDofelem;
            
            const filterParams = {
                id: searchIDofelem || undefined,
                name: filters.nameFilter.toLowerCase() || undefined,
                service: [!filters.product, filters.service],
                is_active: [!filters.archivedFilterNotArchive, filters.archivedFilterActive],
                company: filters.organFilter || undefined,
                limit,
                offset: currentOffset
            };

            const response = isFiltering 
                ? await api.post('/api/v1/bills/nomenclaturefilter/', filterParams)
                : await api.get(`/api/v1/bills/nomenclature/?limit=${limit}&offset=${currentOffset}`);

            const newData = response.data.results || response.data;
            setNomenclature(isNewSearch ? newData : prev => [...prev, ...newData]);
            setHasMore(response.data.next !== null);
        } catch (error) {
            console.error('Ошибка загрузки номенклатуры', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/v1/organization/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Ошибка загрузки компаний', error);
        }
    };

    // Дебаунсированный поиск
    const debouncedSearch = useCallback(
        debounce(() => {
            setOffset(0);
            setHasMore(true);
            fetchNomenclature(0, true);
        }, 300),
        [filters, searchIDofelem]
    );

    useEffect(() => {
        fetchCompanies();
    }, []); 

    useEffect(() => {
        debouncedSearch();
        return () => debouncedSearch.cancel();
    }, [filters, searchIDofelem, debouncedSearch]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            const scrollThreshold = 50;
            
            if (scrollHeight - (scrollTop + clientHeight) <= scrollThreshold && hasMore && !loading) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    // Обновление архивного статуса номенклатуры
    const handleUpdateNomenclature = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(checkedNomenclature.map(id => 
                api.patch(`/api/v1/bills/nomenclature/${id}/update/`, { is_active: isActive })
            ));
            
            // Обновление списка
            setNomenclature([]);
            fetchNomenclature();
            setOffset(0);
            setHasMore(true);
            setCheckedNomenclature([]);
        } catch (error) {
            console.error(`Ошибка ${isActive ? 'разархивации' : 'архивации'}`, error);
        } finally {
            setLoading(false);
        }
    };

    // Обработчики выделения и снятия выделения
    const handleCheckboxChange = (orgId) => {
        setCheckedNomenclature(prev => 
            prev.includes(orgId) 
                ? prev.filter(id => id !== orgId)
                : [...prev, orgId]
        );
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setCheckedNomenclature(
            isChecked ? nomenclature.map(item => item.id) : []
        );
    };

    const allChecked = nomenclature.length > 0 && 
        nomenclature.every(item => checkedNomenclature.includes(item.id));

    // Сброс фильтров
    const handleReset = () => {
        setFilters({
            nameFilter: '',
            organFilter: '',
            service: false,
            product: false,
            archivedFilterNotArchive: false,
            archivedFilterActive: false
        });
        setOffset(0);
        setHasMore(true);
        setNomenclature([]);
        navigate(location.pathname);
        window.location.reload();
    };

    return (
        <div className="container-fluid" translate="no">
            <Header />
            <div className="row flex-nowrap">
                <SideBar />
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Номенклатура</h3>
                                    <div>
                                        <ProtectedContent perms={'payment.change_individual'}>
                                            <button 
                                                onClick={() => handleUpdateNomenclature(false)} 
                                                className="btn btn-secondary me-2"
                                                disabled={checkedNomenclature.length === 0}
                                            >
                                                Архивировать выбранные
                                            </button>
                                            <button 
                                                onClick={() => handleUpdateNomenclature(true)} 
                                                className="btn btn-success me-2"
                                                disabled={checkedNomenclature.length === 0}
                                            >
                                                Разархивировать выбранные
                                            </button>
                                        </ProtectedContent>
                                        <button onClick={() => setOffset(0)} className="btn btn-primary">Обновить</button>
                                    </div>
                                </div>
                                <div className="mb-3 p-3 border rounded">
                                    <h5>Фильтры:</h5>
                                    <div className="row">
                                        <div className="col">
                                            <label className='form-label'>Наименование номенклатуры</label>
                                            <input
                                                type="text"
                                                placeholder="Введите наименование"
                                                value={filters.nameFilter}
                                                onChange={(e) => handleFilterChange('nameFilter', e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className='form-label'>Организация</label>
                                            <APIMultipleSearchableSelect
                                                endpoint="/api/v1/organization/search/"
                                                value={filters.organFilter}
                                                onChange={(value) => handleFilterChange('organFilter', value)}
                                                placeholder="Выберите организацию"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className='form-label'>Тип номенклатуры</label>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="service"
                                                    checked={filters.service}
                                                    onChange={(e) => handleFilterChange('service', e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="service">
                                                    Услуга
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="product"
                                                    checked={filters.product}
                                                    onChange={(e) => handleFilterChange('product', e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="product">
                                                    Товар
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <label>Архивная номенклатура</label>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckNotActive"
                                                    checked={filters.archivedFilterNotArchive}
                                                    onChange={(e) => handleFilterChange('archivedFilterNotArchive', e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                    Да
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckActive"
                                                    checked={filters.archivedFilterActive}
                                                    onChange={(e) => handleFilterChange('archivedFilterActive', e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckActive">
                                                    Нет
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col align-self-end">
                                            <button
                                                onClick={handleReset}
                                                className="btn btn-outline-secondary mt-3"
                                            >
                                                Сбросить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div ref={containerRef} className="table-responsive" style={{ height: '55vh', overflowY: 'scroll' }}>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input 
                                                        type="checkbox" 
                                                        checked={allChecked}
                                                        onChange={handleSelectAllChange}
                                                    />
                                                </th>
                                                <th>Наименование номенклатуры</th>
                                                <th>Организация</th>
                                                <th>Тип номенклатуры</th>
                                                <th>Архивный</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {nomenclature.map((item) => (
                                                <tr key={item.name}>
                                                    <td>
                                                        <input 
                                                            type="checkbox"
                                                            checked={checkedNomenclature.includes(item.id)}
                                                            onChange={() => handleCheckboxChange(item.id)}
                                                        />
                                                    </td> 
                                                    <td style={{ 
                                                        width: '500px', 
                                                        maxWidth: '500px', 
                                                        whiteSpace: 'normal', 
                                                        wordWrap: 'break-word' 
                                                    }}>{item.name}</td>
                                                    <td style={{ 
                                                        width: '400px', 
                                                        maxWidth: '400px', 
                                                        whiteSpace: 'normal', 
                                                        wordWrap: 'break-word' 
                                                    }}>{item.company ? companies.find(company => company.id === item.company)?.name : ''}</td>
                                                    <td style={{ 
                                                        width: '200px', 
                                                        maxWidth: '200px', 
                                                        whiteSpace: 'normal', 
                                                        wordWrap: 'break-word' 
                                                    }}>{item.service ? 'Услуга' : 'Товар'}</td>
                                                    <td style={{ 
                                                        width: '150px', 
                                                        maxWidth: '150px', 
                                                        whiteSpace: 'normal', 
                                                        wordWrap: 'break-word' 
                                                    }}>{item.is_active ? 'Нет' : 'Да'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {loading && 
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Nomenclature;