import React, { useState, useEffect, useRef } from 'react';
import api from '../../api';
import "../../styles/searchSelect.css";

const EmployeeMultipleSearchableSelect = ({ 
    value = [], 
    onChange, 
    placeholder = "Выберите сотрудников", 
    disabled = false, 
    size = 'sm',
    excludeIds = [],
    selectedManager = null
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [allOptions, setAllOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const wrapperRef = useRef(null);

    // Инициализация выбранных сотрудников
    useEffect(() => {
        const initializeValue = async () => {
            if (value && value.length > 0) {
                try {
                    const response = await api.get(`/api/v1/user/search/`);
                    const initialSelected = response.data.filter(emp => 
                        value.some(v => 
                            (typeof v === 'number' && v === emp.id) || 
                            (typeof v === 'object' && v.id === emp.id)
                        )
                    ).map(employee => ({
                        ...employee,
                        displayName: `${employee.full_name} (${employee.first_name} ${employee.last_name})`,
                        searchText: `${employee.full_name} ${employee.first_name} ${employee.last_name}`.toLowerCase()
                    }));

                    setSelectedOptions(initialSelected);
                } catch (error) {
                    console.error('Error fetching initial employees:', error);
                }
            }
        };

        initializeValue();
    }, [value]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const searchOptions = async (term) => {
        setLoading(true);
        try {
            const response = await api.get(`/api/v1/user/search/?search=${term}`);
            
            // Фильтруем сотрудников, исключая тех, чьи ID переданы в excludeIds или соответствующих selectedManager
            const filteredOptions = response.data
                .filter(employee => 
                    !excludeIds.includes(employee.id) && 
                    employee.id !== (selectedManager?.id || selectedManager) // Исключаем selectedManager
                )
                .map(employee => ({
                    ...employee,
                    displayName: `${employee.full_name} (${employee.first_name} ${employee.last_name})`,
                    searchText: `${employee.full_name} ${employee.first_name} ${employee.last_name}`.toLowerCase()
                }));

            setAllOptions(
                term 
                    ? filteredOptions.filter(employee => 
                        employee.searchText.includes(term.toLowerCase())
                    )
                    : filteredOptions
            );
        } catch (error) {
            console.error('Error searching employees:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (searchTerm) {
            const delayDebounceFn = setTimeout(() => {
                searchOptions(searchTerm);
            }, 500);
            return () => clearTimeout(delayDebounceFn);
        } else if (isOpen) {
            searchOptions('');
        } else {
            setAllOptions([]);
        }
    }, [searchTerm, isOpen]);

    const handleSelect = (employee) => {
        const isSelected = selectedOptions.some(selected => selected.id === employee.id);
        
        let updatedSelectedOptions;
        if (isSelected) {
            updatedSelectedOptions = selectedOptions.filter(selected => selected.id !== employee.id);
        } else {
            updatedSelectedOptions = [...selectedOptions, employee];
        }

        setSelectedOptions(updatedSelectedOptions);
        onChange(updatedSelectedOptions);
        setSearchTerm('');
    };

    const handleToggleDropdown = () => {
        if (disabled) return;
        setIsOpen((prev) => !prev);
        if (!isOpen) {
            searchOptions('');
        }
    };

    // Функция для определения, выбран ли сотрудник
    const isEmployeeSelected = (employeeId) => {
        return selectedOptions.some(selected => selected.id === employeeId);
    };

    return (
        <div className="searchable-select" ref={wrapperRef}>
            <div 
                className={`form-select form-select-${size} d-flex justify-content-between align-items-center ${disabled ? 'disabled' : ''}`}
                onClick={handleToggleDropdown}
            >
                <span className='options'>
                    {selectedOptions.length > 0 
                        ? selectedOptions.map(opt => opt.first_name + ' ' +  opt.last_name).join(', ')
                        : placeholder
                    }
                </span>
            </div>
            {isOpen && (
                <div className="select-dropdown">
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Поиск сотрудников..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onClick={(e) => e.stopPropagation()}
                    />
                    {loading ? (
                        <div className="text-center">Загрузка...</div>
                    ) : (
                        <ul className="list-group">
                            {allOptions.map((employee) => (
                                <li
                                    key={employee.id}
                                    className={`list-group-item ${isEmployeeSelected(employee.id) ? 'selected' : ''}`}
                                    onClick={() => handleSelect(employee)}
                                >
                                    {employee.first_name} {employee.last_name}
                                    {isEmployeeSelected(employee.id) && (
                                        <span className="red-x">✖</span>
                                    )}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
};

export default EmployeeMultipleSearchableSelect;