import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "../styles/homePageStyle.css";
import api from "../api";
import ProtectedContent from './ProtectedContent';
import { Modal} from 'react-bootstrap';

const IntegrationContent = () => {
  const [isModal, setModal] = useState(false);
  const [integrations, setIntegrations] = useState([]);

  useEffect(() => {
    fetchIntegrations();
  }, []);

  const fetchIntegrations = async () => {
    try {
      const response = await api.get('/api/v1/connection/');
      setIntegrations(response.data);
    } catch (error) {
      console.error('Error fetching integrations', error);
    }
  };
  const handleCancel = () => {
    setModal(false);
  };

  const handleAddIntegration = async (e) =>  {
    e.preventDefault();
    const integrationName = document.getElementById("integrationName").value;
    try {
      const response = await api.post('/api/v1/connection/create/', {'name': integrationName}
      );
      console.log('Response:', response.data);
      setModal(false);
      fetchIntegrations();
    } catch (error) {
        alert('Заполните все поля')
        console.error('Error:', error);
    }
  };
  const downloadInstruction = async () => {
    try {
      const response = await api.get('/api/v1/file/instruction/download/', {
        responseType: 'blob', // Important: set the response type to blob
      });
      
      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      
      // Create a link element and trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Instruction_1c.docx'); // Set the desired filename
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      
      console.log('File downloaded successfully');
    } catch (error) {
      alert('Ошибка скачивания');
      console.error('Error:', error);
    }
  };
  const downloadExtension = async () => {
    try {
      const response = await api.get('/api/v1/file/extension/download/', {
        responseType: 'blob', // Important: set the response type to blob
      });
      
      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      
      // Create a link element and trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'WebUpravlenka.cfe'); // Set the desired filename
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      
      console.log('File downloaded successfully');
    } catch (error) {
      alert('Ошибка скачивания');
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <ProtectedContent perms={'connection.add_connection'}>
        <button 
          className="btn btn-secondary me-2 btn-orange text-black"
          onClick={() => setModal(true)}
        >
          + Добавить интеграцию
        </button>
      </ProtectedContent>
      <Modal show={isModal} onHide={handleCancel} centered>
            <Modal.Header closeButton>
                <Modal.Title>Добавить интеграцию</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="bankaccount-form" onSubmit={handleAddIntegration}>
                <div>
            <div className="mb-3">
              <label htmlFor="integrationName" className="form-label">Наименование</label>
              <input type="text" className="form-control" id="integrationName" placeholder="Введите наименование" />
            </div>
            <div className="mb-3">
              <label htmlFor="configSelect" className="form-label">Конфигурация</label>
              <select className="form-select" id="configSelect">
                <option value="config1">Бухгалтерия для Казахстана 8.3</option>
                <option value="config2">Конфигурация 2</option>
                <option value="config3">Конфигурация 3</option>
              </select>
            </div>
            <div className="mb-3"><a href="#" onClick={(e) => {
              e.preventDefault();
              downloadExtension();
              }}>Скачать расширение 1С Бухгалтерия</a></div>
            <div className="mb-3"><a href="#" onClick={(e) => {
              e.preventDefault();
              downloadInstruction();
              }}>Скачать инструкцию для импорта данных</a></div>
          </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                    Закрыть
                </button>
                <button className="btn btn-primary" onClick={handleAddIntegration}>
                    Сохранить
                </button>
            </Modal.Footer>
        </Modal>
      <div className="mt-4">
        {integrations.map((integration, index) => (
          <div key={index} className="card mb-3 integration-card">
            <div className="card-body d-flex justify-content-between align-items-left">
              <div className="d-flex align-items-center">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                <div className="ms-3">
                  <h5 className="card-title mb-1">{integration.name}</h5>
                  <p className="card-text mb-1">Бухгалтерия для Казахстана 8.3</p>
                  <p className="card-text mb-1">Token: {integration.jwt_token}</p>
                  <p className="card-text mb-0">Дата создания: {new Date().toLocaleDateString()}</p>
                </div>
                <div className="ms-5">
                  <button className="btn btn-light me-2">
                  <i className="bi bi-files"></i>
                </button>
                <button className="btn btn-light me-2">
                  <i className="bi bi-eraser"></i>
                </button>
                <button className="btn btn-light">
                  <i className="bi bi-trash"></i>
                </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IntegrationContent;
