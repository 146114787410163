import { useNavigate } from "react-router-dom";

const ProjectProcentTable = ( {monthProjects, month} ) => {
    
    const navigate = useNavigate()

    const handleRowClick = (monthProject) => {
        navigate(`/salaries/${month}/${monthProject.project.id}`)
    }

    return (
        <table className="table table-striped">
            <thead className="table-light">
                <tr>
                    <th>Наименование проекта</th>
                    <th>Разнесено сотрудников</th>
                    <th>Дата создания проекта</th>
                </tr>
            </thead>
            <tbody>
                {monthProjects.map((monthProject) => (
                    <tr key={monthProject.project} onDoubleClick={() => handleRowClick(monthProject)}>
                        <td>{monthProject.project ? monthProject.project.name : ''}</td>
                        <td>{monthProject.fill_percent}</td>
                        <td>{monthProject.project ? monthProject.project.project_date : ''}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
 
} 

export default ProjectProcentTable;